import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
  useColorModeValue,
  useColorModeValue as mode,
  Circle,
  AspectRatio,
  Image,
  Skeleton,
  Progress,
  Badge,
  VStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Input,
  Divider,
  FormControl,
  useDisclosure,
  Avatar,
  ButtonGroup,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { useKeenSlider } from "keen-slider/react";
import Swal from "sweetalert2";
import CurrencyFormat from "react-currency-format";
// using version 6.6.10
import { auth, db, storage } from "../../../Configs/firebase";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { CreateInvoicePaymentLink } from "../../../Configs/xendit";
import moment from "moment";
import {
  doc,
  getDoc,
  serverTimestamp,
  collection,
  query,
  getDocs,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import colors from "../../../Configs/colors";
import { order_id } from "../../../Api/Admin/apiOrders";

const AppCardPropertyDetail = () => {
  const images = [
    {
      id: "01",
      src: "/rumah.jpeg",
      alt: "Awesome watch",
    },
    {
      id: "02",
      src: "/kosan.jpeg",
      alt: "Awesome watch",
    },
    {
      id: "03",
      src: "https://images.unsplash.com/photo-1568010434570-74e9ba7126bc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80",
      alt: "Awesome watch",
    },
    {
      id: "04",
      src: "https://images.unsplash.com/photo-1569411032431-07598b0012c2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
      alt: "Awesome watch",
    },
    {
      id: "05",
      src: "https://images.unsplash.com/photo-1565440962783-f87efdea99fd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=936&q=80",
      alt: "Awesome watch",
    },
    {
      id: "06",
      src: "https://images.unsplash.com/photo-1548169874-53e85f753f1e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1420&q=80",
      alt: "Awesome watch",
    },
  ];

  const Gallery = (props) => {
    const Carousel = React.forwardRef(function Carousel(props, ref) {
      return (
        <Flex
          ref={ref}
          className="chakra-carousel"
          overflow="hidden"
          position="relative"
          userSelect="none"
          {...props}
        />
      );
    });
    const CarouselSlide = (props) => (
      <Box
        className="chakra-carousel__slide"
        position="relative"
        overflow="hidden"
        width="100%"
        minH="100%"
        {...props}
      />
    );
    const CarouselIconButton = (props) => (
      <IconButton
        variant="unstyled"
        boxSize="auto"
        minW="auto"
        display="inline-flex"
        fontSize="2xl"
        color={useColorModeValue("gray.600", "gray.400")}
        _hover={{
          color: useColorModeValue("blue.500", "blue.300"),
          _disabled: {
            color: useColorModeValue("gray.600", "gray.400"),
          },
        }}
        _active={{
          color: useColorModeValue("blue.600", "blue.400"),
        }}
        _focus={{
          boxShadow: "none",
        }}
        _focusVisible={{
          boxShadow: "outline",
        }}
        {...props}
      />
    );
    const useCarousel = (options) => {
      const defaultOptions = {
        selector: ".chakra-carousel__slide",
      };
      return useKeenSlider({ ...defaultOptions, ...options });
    };
    const { images, aspectRatio = 4 / 3, rootProps } = props;
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [ref, slider] = useCarousel({
      slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
    });
    const hasPrevious = currentSlide !== 0;
    const hasNext = currentSlide < images.length - 1;
    return (
      <Stack spacing="4" {...rootProps}>
        <Box
          position="relative"
          sx={{
            _hover: {
              "> button": {
                display: "inline-flex",
              },
            },
          }}
        >
          <Carousel ref={ref}>
            {images.map((image, i) => (
              <CarouselSlide key={i}>
                <AspectRatio
                  ratio={aspectRatio}
                  transition="all 200ms"
                  opacity={currentSlide === i ? 1 : 0.4}
                  _hover={{
                    opacity: 1,
                  }}
                >
                  <Image
                    src={propertyImage}
                    objectFit="cover"
                    alt={image.alt}
                    fallback={<Skeleton />}
                  />
                </AspectRatio>
              </CarouselSlide>
            ))}
          </Carousel>
          {hasPrevious && (
            <CarouselIconButton
              pos="absolute"
              left="3"
              top="50%"
              transform="translateY(-50%)"
              onClick={() => slider.current?.prev()}
              icon={<IoChevronBackOutline />}
              aria-label="Previous Slide"
            />
          )}

          {hasNext && (
            <CarouselIconButton
              pos="absolute"
              right="3"
              top="50%"
              transform="translateY(-50%)"
              onClick={() => slider.current?.next()}
              icon={<IoChevronForwardOutline />}
              aria-label="Next Slide"
            />
          )}
          <HStack
            position="absolute"
            width="full"
            justify="center"
            bottom="0"
            py="4"
          >
            {images.map((_, index) => (
              <Circle
                key={index}
                size="2"
                bg={currentSlide == index ? "white" : "whiteAlpha.400"}
              />
            ))}
          </HStack>
        </Box>
      </Stack>
    );
  };

  const formatPrice = (value, opts = {}) => {
    const { locale = "en-US", currency = "USD" } = opts;
    const formatter = new Intl.NumberFormat(locale, {
      currency,
      style: "currency",
      maximumFractionDigits: 2,
    });
    return formatter.format(value);
  };

  const PriceTag = (props) => {
    const {
      price,
      currency,
      salePrice,
      rootProps,
      priceProps,
      salePriceProps,
    } = props;
    return (
      <HStack spacing="1" {...rootProps}>
        <Price isOnSale={!!salePrice} textProps={priceProps}>
          {formatPrice(price, {
            currency,
          })}
        </Price>
        {salePrice && (
          <SalePrice {...salePriceProps}>
            {formatPrice(salePrice, {
              currency,
            })}
          </SalePrice>
        )}
      </HStack>
    );
  };

  const Price = (props) => {
    const { isOnSale, children, textProps } = props;
    const defaultColor = mode("gray.700", "gray.400");
    const onSaleColor = mode("gray.400", "gray.700");
    const color = isOnSale ? onSaleColor : defaultColor;
    return (
      <Text
        as="span"
        fontWeight="medium"
        color={color}
        textDecoration={isOnSale ? "line-through" : "none"}
        {...textProps}
      >
        {children}
      </Text>
    );
  };

  const SalePrice = (props) => (
    <Text
      as="span"
      fontWeight="semibold"
      color={mode("gray.800", "gray.100")}
      {...props}
    />
  );

  function AppCardPropertyTable() {
    var moment = require("moment");
    const [transaction, setTransaction] = useState([]);
    const { id } = useParams();

    // const user = auth.currentUser.email
    // console.log('firebase auth', user)

    const getData = async () => {
      let arr = [];
      // const emailData = sessionStorage.getItem("email")
      const col = collection(db, "orders");
      const q = query(col, where("property", "==", id));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });
      setTransaction(arr);
    };

    useEffect(() => {
      getData();
      return () => {
        setTransaction([]);
      };
    }, []);

    const MemberTable = (props) => (
      <Table {...props} boxShadow="lg">
        <Thead backgroundColor={colors.theme}>
          <Tr>
            <Th>
              <HStack spacing="3">
                <HStack spacing="1">
                  <Text>Transaction</Text>
                </HStack>
              </HStack>
            </Th>
            <Th>User</Th>
            <Th>Property</Th>
            <Th>Lot</Th>
            <Th>Amount</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {transaction.map((member, index) => (
            <Tr key={index}>
              <Td>
                <HStack spacing="3">
                  <Avatar
                    name={member.property}
                    src={member.image}
                    boxSize="10"
                  />
                  <Box>
                    <Text fontWeight="medium">{member.property}</Text>
                    <Text color="muted">{member.time}</Text>
                  </Box>
                </HStack>
              </Td>
              <Td>
                <Text color="muted">{member.email}</Text>
              </Td>
              <Td>
                <Text color="muted">{member.type}</Text>
              </Td>
              <Td>
                <Text color="muted">{member.sold}</Text>
              </Td>
              <Td>
                <CurrencyFormat
                  value={member.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"IDR "}
                />
              </Td>
              <Td></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );

    return (
      <>
        {!transaction.length ? (
          <Heading as="h1" size="xl" textAlign="center">
            No Transaction
          </Heading>
        ) : (
          <>
            <Center mt={8}>
              <Text fontWeight="bold" fontSize="20px">
                Transaction History
              </Text>
            </Center>
            <Box bg="bg-surface">
              <Stack spacing="5">
                <Box px={{ base: "4", md: "6" }} pt="5"></Box>
                <Box overflowX="auto" px={12}>
                  <MemberTable />
                </Box>
                <Box px={{ base: "4", md: "6" }} pb="5">
                  <HStack spacing="3" justify="space-between">
                    <Text color="muted" fontSize="sm">
                      Showing 1 to 5 of 42 results
                    </Text>

                    <ButtonGroup
                      spacing="3"
                      justifyContent="space-between"
                      width={{ base: "full", md: "auto" }}
                      variant="secondary"
                    >
                      <Button>Previous</Button>
                      <Button>Next</Button>
                    </ButtonGroup>
                  </HStack>
                </Box>
              </Stack>
            </Box>
          </>
        )}
      </>
    );
  }

  let navigate = useNavigate();
  let param = useParams();

  const [propertyName, setPropertyName] = React.useState("");
  const [propertyType, setPropertyType] = React.useState("");
  const [propertyLotPrice, setPropertyLotPrice] = React.useState("");
  const [propertyLot, setPropertyLot] = React.useState("");
  const [propertyDividen, setPropertyDividen] = React.useState("");
  const [propertyPeriode, setPropertyPeriode] = React.useState("");
  const [propertyStatus, setPropertyStatus] = React.useState("");
  const [propertyImage, setPropertyImage] = React.useState("");
  const [propertyDescription, setPropertyDescription] = React.useState("");
  const [propertySold, setPropertySold] = React.useState("");
  const [totalPeriode, setTotalPeriode] = React.useState("");
  const [steps, setSteps] = React.useState(false);
  const [approved, setApproved] = React.useState(false);
  const [lotQty, setLotQty] = React.useState(1);
  const [prospektus, setProspektus] = React.useState("");
  const [propertyId, setPropertyId] = React.useState("");

  const [payment, setPayment] = React.useState("Bank Transfer");
  console.log("payment", payment);

  console.log(lotQty);

  const getData = async () => {
    const docRef = doc(db, "property", param.id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      const data = docSnap.data();
      setPropertyName(data.name);
      setPropertyType(data.type);
      setPropertyLotPrice(data.lotPrice);
      setPropertyLot(data.lot);
      setPropertyDividen(data.dividen);
      setPropertyPeriode(data.periode);
      setPropertyStatus(data.status);
      setApproved(data.approved);
      setPropertyImage(data.imageUrl);
      setPropertyDescription(data.description);
      setPropertySold(data.sold);
      setTotalPeriode(data.fullPeriode);
      setProspektus(data.prospektus);
      setPropertyId(data.property_id);
    } else {
      console.log("No such document!");
      navigate("/404", { replace: true });
    }
  };

  const getDataOrder = async () => {
    let arr = [];
    const q = query(collection(db, "orders"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const obj = doc.data();
      obj.id = doc.id;
      arr.push(obj);
      // console.log(obj)
    });
    return arr;
  };

  const handleTransaction = async () => {
    const user = auth.currentUser.uid;
    try {
      setSteps(true);
      const createOrderId = await getDataOrder();

      let orderCount = createOrderId.length;
      console.log(orderCount);

      let invData = {
        user_id: user,
        orderId: "PCI00" + ++orderCount,
        invoice_id: "INV00" + orderCount,
      };

      const external_id =
        invData.orderId +
        "-" +
        invData.invoice_id +
        "-" +
        invData.user_id +
        "-" +
        moment().format("YYYYMMDDhhmm") +
        "-" +
        order_id +
        "-" +
        propertyId +
        "-" +
        lotQty;

      const totalPrice = propertyLotPrice * lotQty;
      const amount = totalPrice;
      const email = auth.currentUser.email;

      if (payment === "Bank Transfer") {
        try {
          const docRef2 = await setDoc(doc(db, "orders", order_id), {
            property: propertyName,
            email: email,
            sold: lotQty,
            type: propertyType,
            amount: totalPrice,
            created_at: serverTimestamp(),
            paid_at: "",
            status: "UNPAID",
            payment_channel: "Bank Transfer",
            external_id: external_id,
            image: propertyImage,
            userID: user,
            order_id: order_id,
          });

          console.log("Document written with ID", order_id);
          navigate(`/transaction/${order_id}/payment`);
        } catch (error) {
          console.log(error);
        }
      }

      if (payment === "Virtual Account") {
        const createInvoice = await CreateInvoicePaymentLink(
          external_id,
          amount,
          invData.invoice_id,
          email
        );

        const invoiceData = createInvoice.data;

        const docRef = await setDoc(doc(db, "orders", order_id), {
          property: propertyName,
          email: email,
          sold: lotQty,
          type: propertyType,
          amount: totalPrice,
          created_at: serverTimestamp(),
          paid_at: "",
          status: "UNPAID",
          payment_channel: "",
          external_id: external_id,
          image: propertyImage,
          userID: user,
          order_id: order_id,
        });
        console.log("Document written with ID: ", invData.order_id);

        // Save to Orders
        const inv = await setDoc(
          doc(db, `orders/${order_id}/invoice`, invData.invoice_id),
          invoiceData
        );
        console.log(inv, "set trans admin");

        // Save to User
        const invUsers = await setDoc(
          doc(db, `users/${user}/invoice`, invData.invoice_id),
          invoiceData
        );

        window.location.href = invoiceData.invoice_url;

        console.log("Document written with ID: ", order_id);
        console.log(invUsers, "set trans user");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const storage = getStorage();
  const starsRef = ref(storage, prospektus);
  const handleProspektus = (e) => {
    getDownloadURL(starsRef).then((downloadURL) => {
      console.log(downloadURL);
      window.open(downloadURL);
    });
  };

  useEffect(() => {
    getData();
    return () => {};
  }, []);

  return (
    <Box
      maxW="7xl"
      mx="auto"
      px={{
        base: "0",
        md: "2",
        lg: "6",
      }}
      py={{
        base: "2",
        md: "6",
        lg: "8",
      }}
    >
      <Stack
        direction={{
          base: "column-reverse",
          lg: "row",
        }}
        spacing={{
          base: "6",
          lg: "12",
          xl: "16",
        }}
      >
        <Stack
          spacing={{
            base: "6",
            lg: "3",
          }}
          maxW={{
            lg: "sm",
          }}
          justify="center"
        >
          <Stack
            spacing={{
              base: "3",
              md: "4",
            }}
          >
            <Stack spacing="3">
              <Badge
                borderRadius="10"
                px="2"
                colorScheme="yellow"
                maxW="100px"
                shadow="base"
              >
                <Center>{propertyType}</Center>
              </Badge>
              <Heading size="lg" fontWeight="medium">
                {propertyName}
              </Heading>
            </Stack>
            <HStack spacing="4">
              <PriceTag
                price={propertyLot * propertyLotPrice}
                currency="IDR"
                rootProps={{
                  fontSize: "xl",
                }}
              />
              <Divider orientation="vertical" />
              <Heading size="sm" fontWeight="medium">
                {propertyLot} Lot
              </Heading>
            </HStack>
            <HStack>
              <Text color={useColorModeValue("gray.600", "gray.400")}>
                {propertyDescription}
              </Text>
            </HStack>
            <Box>
              <Progress
                size="sm"
                value={propertySold}
                max={propertyLot}
                colorScheme="yellow"
                hasStripe={true}
                isAnimated={true}
              />
            </Box>

            <Box
              pr={{
                base: "4",
                md: "0",
                lg: "12",
              }}
              maxH="200"
              maxW="full"
            >
              <VStack spacing="20px">
                <HStack justifyContent="space-around">
                  <Flex
                    flexDirection="column"
                    maxHeight="auto"
                    w="150px"
                    h="50px"
                    backgroundColor={colors.theme}
                    align="center"
                    justifyContent="center"
                    borderRadius={10}
                    boxShadow="md"
                  >
                    <Heading
                      color="gray.500"
                      fontWeight="semibold"
                      letterSpacing="wide"
                      fontSize="xs"
                      textTransform="uppercase"
                    >
                      Harga per Lot
                    </Heading>
                    <PriceTag
                      price={propertyLotPrice}
                      currency="IDR"
                      rootProps={{
                        fontWeight: "semibold",
                        fontSize: "sm",
                        textTransform: "uppercase",
                      }}
                    />
                  </Flex>
                  <Flex
                    flexDirection="column"
                    maxHeight="auto"
                    w="150px"
                    h="50px"
                    backgroundColor={colors.theme}
                    align="center"
                    justifyContent="center"
                    borderRadius={10}
                    boxShadow="md"
                  >
                    <Heading
                      color="gray.500"
                      fontWeight="semibold"
                      letterSpacing="wide"
                      fontSize="xs"
                      textTransform="uppercase"
                    >
                      Lot Tersisa
                    </Heading>
                    <Text
                      fontWeight="semibold"
                      fontSize="sm"
                      textTransform="uppercase"
                    >
                      {propertyLot - propertySold}
                    </Text>
                  </Flex>
                </HStack>

                <HStack justifyContent="space-around">
                  <Flex
                    flexDirection="column"
                    maxHeight="auto"
                    w="150px"
                    h="50px"
                    backgroundColor={colors.theme}
                    align="center"
                    justifyContent="center"
                    borderRadius={10}
                    boxShadow="md"
                  >
                    <Heading
                      color="gray.500"
                      fontWeight="semibold"
                      letterSpacing="wide"
                      fontSize="xs"
                      textTransform="uppercase"
                    >
                      Periode Dividen
                    </Heading>
                    <Text
                      fontWeight="semibold"
                      fontSize="sm"
                      textTransform="uppercase"
                    >
                      {propertyPeriode + " Bulan"} || {totalPeriode / 12 + "Th"}
                    </Text>
                  </Flex>
                  <Flex
                    flexDirection="column"
                    maxHeight="auto"
                    w="150px"
                    h="50px"
                    backgroundColor={colors.theme}
                    align="center"
                    justifyContent="center"
                    borderRadius={10}
                    boxShadow="md"
                  >
                    <Heading
                      color="gray.500"
                      fontWeight="semibold"
                      letterSpacing="wide"
                      fontSize="xs"
                      textTransform="uppercase"
                    >
                      Estimasi Dividen
                    </Heading>
                    <Text
                      fontWeight="semibold"
                      fontSize="sm"
                      textTransform="uppercase"
                    >
                      {propertyDividen + " % / Tahun"}
                    </Text>
                  </Flex>
                </HStack>
                <Button
                  colorScheme="yellow"
                  w={200}
                  shadow="base"
                  onClick={handleProspektus}
                >
                  Lihat Prospektus
                </Button>
              </VStack>
            </Box>
          </Stack>

          <HStack
            spacing={{
              base: "4",
              md: "8",
            }}
            align="flex-end"
            justify="space-evenly"
          >
            <Box flex="1" mb={5}>
              <Heading
                color="gray.500"
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="xs"
                textTransform="uppercase"
                mt={2}
                mb={4}
              >
                Masukan Jumlah LOT ({propertyLot - propertySold} Lot Tersisa)
              </Heading>
              <FormControl>
                <NumberInput
                  min={1}
                  max={propertyLot - propertySold}
                  focusInputOnChange={true}
                  keepWithinRange={true}
                  isReadOnly={true}
                >
                  <NumberInputField
                    placeholder={lotQty}
                    onChange={(e) => setLotQty(e.target.value)}
                  />
                  <NumberInputStepper>
                    {lotQty >= propertyLot - propertySold ? (
                      <NumberIncrementStepper isDisabled={true} />
                    ) : (
                      <NumberIncrementStepper
                        onClick={() => setLotQty(lotQty + 1)}
                      />
                    )}
                    {lotQty > 0 ? (
                      <NumberDecrementStepper
                        onClick={() => setLotQty(lotQty - 1)}
                      />
                    ) : (
                      <NumberDecrementStepper isDisabled={true} />
                    )}
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
            </Box>
          </HStack>
          <FormControl>
            TOTAL:
            <PriceTag
              price={propertyLotPrice * lotQty}
              currency="IDR"
              rootProps={{
                fontSize: "xl",
              }}
            />
          </FormControl>
          {lotQty > 0 ? (
            <>
              <Divider />
              <Box>
                <Heading fontSize="md">Pilih Cara Pembayaran:</Heading>
                <RadioGroup mt={3} defaultValue="Bank Transfer">
                  <Stack spacing={5} direction="row">
                    <Radio
                      colorScheme="yellow"
                      value="Bank Transfer"
                      onChange={(e) => setPayment(e.target.value)}
                    >
                      Bank Transfer
                    </Radio>
                    <Radio
                      isDisabled={true}
                      colorScheme="yellow"
                      value="Virtual Account"
                      onChange={(e) => setPayment(e.target.value)}
                    >
                      Virtual Account
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Box>
            </>
          ) : (
            <></>
          )}
          <Divider />
          {lotQty === 0 ? (
            <Button colorScheme="red" size="lg" isDisabled={true}>
              Beli Lot Sekarang
            </Button>
          ) : (
            <Button
              colorScheme="yellow"
              size="lg"
              onClick={() => handleTransaction()}
            >
              Beli Lot Sekarang
            </Button>
          )}
        </Stack>
        <Gallery
          rootProps={{
            overflow: "hidden",
            flex: "1",
          }}
          images={images.slice(0, 5)}
        />
      </Stack>

      <Box mt="100px">
        <AppCardPropertyTable />
      </Box>
    </Box>
  );
};

export default AppCardPropertyDetail;
