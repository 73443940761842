import React from "react";
import MainLayout from "../Components/Layouts/MainLayout";
import AppBarKprSyariah from "../Components/AppBars/AppBarKprSyariah";

function KprSyariahPage() {
  const { AppLayout } = MainLayout();

  return <>{AppLayout(AppBarKprSyariah)}</>;
}

export default KprSyariahPage;
