import React from "react";
import AppBarAcademy from "../Components/AppBars/AppBarAcademy";
import MainLayout from "../Components/Layouts/MainLayout";

function AcademyPage() {
  const { AppLayout } = MainLayout();

  return <>{AppLayout(AppBarAcademy)}</>;
}

export default AcademyPage;
