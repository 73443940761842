import {
  Container,
  Stack,
  Heading,
  FormControl,
  Text,
  FormLabel,
  Input,
  Button,
  Link,
  HStack,
  useBreakpointValue,
  Box,
  SimpleGrid,
  Select,
} from "@chakra-ui/react";

import { doc, setDoc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import colors from "../../../Configs/colors";
import { auth, db } from "../../../Configs/firebase";

const AppFormSettingBank = () => {
  const [bankName, setBankName] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [user, setUser] = useState([]);

  let navigate = useNavigate();

  const onHandleRegister = async (response) => {
    const user = auth.currentUser.uid;
    try {
      console.log(bankName, accountHolder, accountNumber);

      const docRef = await updateDoc(doc(db, "users", user), {
        bank_name: bankName,
        account_holder: accountHolder,
        account_number: accountNumber,
      })
        .then(() => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Account Bank has been saved",
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .then(() => {
          navigate("/");
        });
      console.log("Document written with ID: ", docRef);
    } catch (error) {}
  };
  return (
    <Container maxW="2xl" py={{ base: "6", md: "12" }}>
      <Stack spacing="8">
        <Stack spacing="6">
          <Stack spacing="3">
            <Heading
              size={useBreakpointValue({ base: "xs", md: "sm" })}
              fontSize={20}
            >
              Bank Account Setting
            </Heading>
          </Stack>
        </Stack>
        <Box px={5}>
          <Stack spacing={5}>
            <FormControl isRequired>
              <FormLabel htmlFor="accountholder">Bank Name</FormLabel>
              <Input
                id="bankname"
                type="bankname"
                placeholder="Bank Name"
                onChange={(e) => setBankName(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="accountholder">Account Holder</FormLabel>
              <Input
                id="accountholder"
                type="accountholder"
                placeholder="Full Name"
                onChange={(e) => setAccountHolder(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="accountNumber">Account Number</FormLabel>
              <Input
                id="accountNumber"
                type="number"
                placeholder="Account Number"
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </FormControl>
          </Stack>
        </Box>
        <SimpleGrid spacing="4" columns={[1, null, 2]} w="50%">
          <Button
            onClick={() => onHandleRegister()}
            backgroundColor={colors.theme}
            color="black"
          >
            Save
          </Button>
          <Button colorScheme="red" border="1px">
            Cancel
          </Button>
        </SimpleGrid>
        {/* <HStack justify="end" spacing="1">
          <Text fontSize="sm" color="muted">
            Deactive Your Account
          </Text>
          <Link to="/login">
            <Button variant="link" colorScheme="red" size="sm">
              Deactive
            </Button>
          </Link>
        </HStack> */}
      </Stack>
    </Container>
  );
};

export default AppFormSettingBank;
