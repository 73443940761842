import {
  Container,
  Heading,
  HStack,
  Link,
  Stack,
  Tabs,
  Text,
  useBreakpointValue,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Divider,
  AspectRatio,
  Center,
  Grid,
  GridItem,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactPlayer from "react-player/youtube";

import { getLessonDetail, getLessonsIndex } from "../../Api/Admin/apiCourses";
import colors from "../../Configs/colors";

function AppBarAcademyLesson() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1000);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1000);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const param = useParams();
  const navigate = useNavigate();

  const [lessons, setLessons] = useState([]);
  const [lesson, setLesson] = useState("");

  // Get All Lessons Data
  const getLessonsData = async () => {
    const response2 = await getLessonsIndex(param.courseId);
    setLessons(response2);
    console.log(response2, "getLessonsData");
  };

  // Get Specific Lesson
  const getLessonData = async () => {
    const response1 = await getLessonDetail(param.courseId, param.lessonId);
    setLesson(response1);
    console.log(response1, "getLessonData");
  };

  useEffect(() => {
    getLessonsData();
    getLessonData();
    return () => {
      setLessons([]);
      setLesson("");
    };
  }, [param.lessonId]);

  return (
    <Container maxW="container.xl" py="8" flex="1" height="99vh">
      <Stack
        spacing={{
          base: "8",
          lg: "6",
        }}
      >
        <Stack
          spacing="4"
          justify="space-between"
          backgroundColor={colors.theme}
          boxShadow="md"
          py={5}
          pl={isDesktop ? 10 : 3}
          borderRadius={5}
        >
          <Heading
            size={useBreakpointValue({
              base: "xs",
              lg: "sm",
            })}
            fontWeight="Bold"
            fontSize={isDesktop ? "230%" : "100%"}
            letterSpacing={1}
            color={colors.dark}
          >
            {lesson.lesson_name}
          </Heading>
        </Stack>
      </Stack>

      <Box
        display="flex"
        flexDirection={isDesktop ? "row" : "column-reverse"}
        pl={isDesktop ? 5 : 0}
        pt={10}
        pr={isDesktop ? 10 : 0}
        pb={10}
      >
        <SimpleGrid spacing={isDesktop ? 2 : 3}>
          {lessons.map((item) => (
            <Box
              key={item.lesson_id}
              maxW={isDesktop ? "200px" : "full"}
              p={2}
              overflowY="hidden"
              rounded={5}
              cursor="pointer"
              bg="yellow.200"
              borderRadius="lg"
              _hover={{ bg: "#ffd600" }}
              onClick={() =>
                navigate(`/academy/${param.courseId}/${item.lesson_id}`)
              }
            >
              <Text>{item.lesson_name}</Text>
            </Box>
          ))}
        </SimpleGrid>
        <Box alignItems="center" ml={isDesktop ? 10 : 0} mb={5}>
          <ReactPlayer
            url={lesson.lesson_videoUrl}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  controls: 1,
                  modestbranding: 1,
                  rel: 0,
                  autoplay: 1,
                  loop: 1,
                  cc_load_policy: 1,
                  iv_load_policy: 3,
                  autohide: 1,
                  playsinline: 1,
                  enablejsapi: 1,
                },
              },
            }}
            width={isDesktop ? "820px" : "320px"}
            height={isDesktop ? "461px" : "200px"}
          />
        </Box>
      </Box>
    </Container>
  );
}

export default AppBarAcademyLesson;
