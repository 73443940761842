import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Container,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  useBreakpointValue,
  useDisclosure,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
} from "@chakra-ui/react";
import * as React from "react";

import { Sidebar } from "./AppBarSide";
import { ToggleButton } from "../AppComponents/ToogleButton";
import { signOut } from "firebase/auth";
import { auth, db } from "../../Configs/firebase";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  var store = require("store");

  const isDesktop = useBreakpointValue({
    lg: true,
    md: false,
  });

  let navigate = useNavigate();

  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Box
      as="nav"
      bg="#ffffff"
      color="on-accent"
      shadow="sm"
      position="fixed"
      width="100%"
      zIndex="100"
    >
      <Container maxW="800px" p="2">
        <Flex justify="space-between">
          <HStack spacing="4">
            {isDesktop ? null : (
              <Image src="/propertylogo.png" maxWidth="200px" />
            )}
          </HStack>
          {isDesktop ? (
            <></>
          ) : (
            <>
              <ToggleButton
                isOpen={isOpen}
                aria-label="Open Menu"
                onClick={onToggle}
              />
              <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                isFullHeight
                preserveScrollBarGap // Only disabled for showcase
                trapFocus={false}
              >
                <DrawerOverlay />
                <DrawerContent maxW="fit-content">
                  <Sidebar />
                </DrawerContent>
              </Drawer>
            </>
          )}
        </Flex>
      </Container>
    </Box>
  );
};
