import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../Configs/firebase";

const getOrderIndex = async () => {
  let arr = [];
  const q = query(collection(db, "orders"));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    arr.push(doc.data());
  });
  return arr;
};

const getOrderDetail = async (email, type) => {
  let arr = [];
  const q = query(
    collection(db, "orders"),
    where("email", "==", email),
    where("property", "==", type)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    arr.push(doc.data());
  });
  return arr;
};

const order_id =
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

export { getOrderIndex, getOrderDetail, order_id };
