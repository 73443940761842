import React, { useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  HStack,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { FiDownloadCloud } from "react-icons/fi";
import { collection, getDocs } from "firebase/firestore";
import { GrTransaction } from "react-icons/gr";
import { BiBuildingHouse } from "react-icons/bi";

import { AppCard } from "../AppComponents/PropertyComponents/AppCardProperty";
import colors from "../../Configs/colors";
import { db } from "../../Configs/firebase";
import AppCardTableDashboardProperty from "../AppComponents/dashboardComponents/AppCardTableDashboard";
import AppCardTableDashboardTransaction from "../AppComponents/dashboardComponents/AppCardTableDashboardTransaction";
import { SearchIcon } from "@chakra-ui/icons";

function AppBarDashboard() {
  const [property, setProperty] = React.useState([]);
  const [transaction, setTransaction] = React.useState([]);

  const getDataProperty = async () => {
    let arr = [];
    // if (filter === null) {
    const q = collection(db, "property");
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    setProperty(arr);
  };

  const getDataTransaction = async () => {
    let arr = [];
    const q = collection(db, "orders");
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    setTransaction(arr);
  };

  useEffect(() => {
    getDataProperty();
    getDataTransaction();

    return () => {
      setProperty("");
      setTransaction("");
    };
  }, []);

  const Card = (props) => (
    <Center
      minH="3xs"
      bg={colors.light}
      boxShadow={useColorModeValue("lg")}
      borderRadius="lg"
      {...props}
    >
      <VStack>
        <Box boxSize={30}>{props.icon}</Box>
        <Box align="center">
          <Text fontSize={50} fontWeight="bold" color={colors.darklight}>
            {props.count}
          </Text>
        </Box>
        <Box>
          <Text fontSize={20}>{props.name}</Text>
        </Box>
      </VStack>
    </Center>
  );

  const countProperty = property.length;
  console.log("property:", countProperty);

  const countTransaction = transaction.length;
  console.log("transaction:", countTransaction);

  return (
    <Container maxW="container.xl" py="8" flex="1">
      <Stack
        spacing={{
          base: "8",
          lg: "6",
        }}
      >
        <Stack
          spacing="4"
          direction={{
            base: "column",
            lg: "row",
          }}
          justify="space-between"
          align={{
            base: "start",
            lg: "center",
          }}
          backgroundColor={colors.theme}
          boxShadow="md"
          py={5}
          pl={3}
          borderRadius={5}
        >
          <Stack spacing="1" ml={5}>
            <Heading
              size={useBreakpointValue({
                base: "xs",
                lg: "sm",
              })}
              fontWeight="Bold"
              fontSize="230%"
              letterSpacing={1}
              color={colors.dark}
            >
              Dashboard
            </Heading>
            <Text color={colors.darklight} fontSize="80%">
              All important metrics at a glance
            </Text>
          </Stack>
        </Stack>
        <Stack
          spacing={{
            base: "5",
            lg: "6",
          }}
        >
          <SimpleGrid
            columns={{
              base: 1,
              md: 3,
            }}
            gap="6"
          >
            <Card
              count={countProperty}
              name={"Property"}
              icon={<BiBuildingHouse size={30} />}
            />
            <Card
              count={countTransaction}
              name={"Transactions"}
              icon={<GrTransaction size={30} />}
            />
            <Card />
          </SimpleGrid>
        </Stack>
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
          }}
          gap="5"
        >
          <AppCardTableDashboardProperty />
          <AppCardTableDashboardTransaction />
        </SimpleGrid>
      </Stack>
    </Container>
  );
}

export default AppBarDashboard;
