import {
  Box,
  Center,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCoursesIndex } from "../../../Api/Admin/apiCourses";

export default function AppCardAcademy() {
  let navigate = useNavigate();
  const [isDesktop, setDesktop] = useState(window.innerWidth > 680);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 680);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const [data, setData] = useState([]);
  const getCourseData = async () => {
    const courses = await getCoursesIndex();
    console.log(courses);
    setData(courses);
  };

  useEffect(() => {
    getCourseData();
  }, []);

  return (
    <>
      <SimpleGrid columns={isDesktop ? 3 : 1} spacing={10}>
        {data.map((item) => (
          <Box onClick={() => navigate(`/academy/${item.course_id}/`)}>
            <Center py={12}>
              <Box
                // onClick={()=> props.goDetail(props.data.id)}
                role={"group"}
                p={6}
                maxW={"330px"}
                height="100%"
                bg="#ffd600"
                shadow="lg"
                rounded={"lg"}
                pos={"relative"}
                zIndex={1}
                _hover={{
                  transform: "translateY(-5px)",
                }}
                key={item.course_id}
              >
                <Box
                  rounded={"lg"}
                  mt="-5rem"
                  pos={"relative"}
                  height={"200px"}
                  _after={{
                    transition: "all .3s ease",
                    content: '""',
                    w: "full",
                    h: "full",
                    pos: "absolute",
                    top: 5,
                    left: 0,
                    bg: "white",
                    filter: "blur(20px)",
                    zIndex: -1,
                  }}
                >
                  {item.course_image !== "" ? (
                    <Image
                      rounded={"lg"}
                      height={230}
                      width={"15rem"}
                      objectFit={"cover"}
                      src={item.course_image}
                    />
                  ) : (
                    <Image
                      rounded={"lg"}
                      height={230}
                      width={"15rem"}
                      objectFit={"cover"}
                      src="https://realmomjobs.com/wp-content/themes/thrive-theme/inc/assets/images/featured_image.png"
                    />
                  )}
                </Box>
                <Stack pt={10} align={"center"}>
                  <Heading fontSize={"xl"} fontFamily={"body"} fontWeight={500}>
                    {item.course_name}
                  </Heading>
                </Stack>
              </Box>
            </Center>
          </Box>
        ))}
      </SimpleGrid>
    </>
  );
}
