import {
  Container,
  Stack,
  Heading,
  FormControl,
  Text,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Link,
  HStack,
  useBreakpointValue,
  SimpleGrid,
  Select,
} from "@chakra-ui/react";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";

import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import colors from "../../../Configs/colors";
import { auth, db } from "../../../Configs/firebase";

const AdminAppFormAddUser = () => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [payment, setPayment] = useState("");
  const [profPayment, setProfPayment] = useState("");
  const [user, setUser] = useState("");

  let navigate = useNavigate();

  const onHandleRegister = async () => {
    console.log(
      name,
      password,
      confirmationPassword,
      email,
      address,
      phoneNumber,
      user
    );
    if (password != confirmationPassword) {
      console.log("Password and confirmation is not match");
      return "";
    }

    createUserWithEmailAndPassword(auth, email, password)
      .then(async (response) => {
        const docRef = await setDoc(doc(db, `users/${response.user.uid}`), {
          email_user: email,
          name_user: name,
          address_user: address,
          phone_number: phoneNumber,
          user_role: user,
          payment_user: payment,
          prof_payment: profPayment,
          approved: false,
        });
        console.log("Document written with ID: ", docRef);
      })
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Account has been saved",
          showConfirmButton: false,
          timer: 3000,
        });
      })
      .then(() => {
        sendEmailVerification(auth.currentUser).then(() => {
          alert("send email berhasil");
        });
      })
      .then(() => {
        navigate("/admin/add");
      })
      .catch((error) => alert(error.message));
  };

  return (
    <Container maxW="4xl" py={{ base: "6", md: "12" }}>
      <Stack spacing="8">
        <Stack spacing="6">
          <Stack spacing="3">
            <Heading
              size={useBreakpointValue({ base: "xs", md: "sm" })}
              fontSize={20}
            >
              Register Account
            </Heading>
          </Stack>
        </Stack>
        <SimpleGrid columns={[1, null, 2]} spacingX={7}>
          <Stack spacing={5}>
            <FormControl isRequired>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                id="name"
                type="name"
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Input
                id="password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormHelperText color={colors.darklight} fontSize="50%">
                At least 8 characters long
              </FormHelperText>
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="address">Address</FormLabel>
              <Input
                id="address"
                type="address"
                onChange={(e) => setAddress(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="payment">Payment</FormLabel>
              <Input
                id="payment"
                type="payment"
                onChange={(e) => setPayment(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="profpayment">Payment Receipt</FormLabel>
              <Input
                placeholder="Image Link"
                id="profpayment"
                type="profpayment"
                onChange={(e) => setProfPayment(e.target.value)}
              />
            </FormControl>
          </Stack>
          {/* blabla */}
          <Stack spacing={5}>
            <FormControl isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="confirmpassword">Confirm Password</FormLabel>
              <Input
                id="confirmpassword"
                type="password"
                onChange={(e) => setConfirmationPassword(e.target.value)}
              />
              <FormHelperText color={colors.darklight} fontSize="50%">
                At least 8 characters long
              </FormHelperText>
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
              <Input
                id="phoneNumber"
                type="number"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="users">Users</FormLabel>
              <Select
                placeholder="Select option"
                onChange={(e) => setUser(e.target.value)}
              >
                <option value="super_admin">Admin Utama</option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
                <option value="user_course">User Course</option>
              </Select>
            </FormControl>
          </Stack>
        </SimpleGrid>
        <SimpleGrid spacing="4" columns={[1, null, 2]} w="50%">
          <Button
            onClick={() => onHandleRegister()}
            backgroundColor={colors.theme}
            color="#ffff"
          >
            Create Account
          </Button>
          <Button
            colorScheme="red"
            border="1px"
            onClick={() => navigate("/admin/add")}
          >
            Cancel
          </Button>
        </SimpleGrid>
        <HStack justify="end" spacing="1">
          <Text fontSize="sm" color="muted">
            Deactive Your Account
          </Text>
          <Link to="/login">
            <Button variant="link" colorScheme="red" size="sm">
              Deactive
            </Button>
          </Link>
        </HStack>
      </Stack>
    </Container>
  );
};

export default AdminAppFormAddUser;
