import React from "react";
import AppBarForclosure from "../Components/AppBars/AppBarForclosure";
import MainLayout from "../Components/Layouts/MainLayout";

function ForclosurePage() {
  const { AppLayout } = MainLayout();

  return <>{AppLayout(AppBarForclosure)}</>;
}

export default ForclosurePage;
