import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  HStack,
  Stack,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Progress,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { db } from "../../../Configs/firebase";
import colors from "../../../Configs/colors";

function AppCardTableDashboardProperty() {
  var moment = require("moment");
  let navigate = useNavigate();

  const [property, setProperty] = useState([]);

  const getData = async () => {
    let arr = [];
    const col = collection(db, "property");
    const q = query(col, orderBy("timestamp", "desc"), limit(5));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    setProperty(arr);
  };

  useEffect(() => {
    getData();
    return () => {
      setProperty([]);
    };
  }, []);

  const MemberTable = (props) => (
    <Table {...props}>
      <Thead backgroundColor={colors.theme}>
        <Tr>
          <Th>
            <HStack spacing="3">
              <HStack spacing="1">
                <Text>Transaction</Text>
              </HStack>
            </HStack>
          </Th>
          <Th>Status</Th>
          <Th>Property</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {property.map((member, index) => (
          <Tr key={index}>
            <Td>
              <HStack spacing="3">
                <Avatar name={member.name} src={member.imageUrl} boxSize="10" />
                <Box w={240}>
                  <Text fontWeight="medium">{member.name}</Text>
                  <Text color="muted">
                    {member.sold + "/" + member.lot} Lot
                  </Text>
                  <Progress max={member.lot} value={member.sold} />
                </Box>
              </HStack>
            </Td>

            <Td>
              <Badge
                size="sm"
                colorScheme={member.shown === true ? "green" : "red"}
              >
                Available
              </Badge>
            </Td>
            <Td>
              <Text color="muted">{member.type}</Text>
            </Td>
            <Td>
              <HStack spacing="1">
                {/* <Link to={`/admin/property/${member.name}`}>
                <IconButton
                  icon={<FiEye fontSize="1.25rem" />}
                  variant="ghost"
                  aria-label="Delete member"
                />
             </Link>    */}
              </HStack>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <Box bg="bg-surface">
      <Stack spacing="5">
        <Box px={{ base: "4", md: "6" }} pt="5">
          <Stack
            direction={{ base: "column", md: "row" }}
            justify="space-between"
          >
            <Box fontSize={18} fontWeight="bold" letterSpacing={2}>
              5 New Property
            </Box>
          </Stack>
        </Box>

        <Box overflowX="auto" boxShadow="lg" rounded={10}>
          <MemberTable />
        </Box>
      </Stack>
    </Box>
  );
}

export default AppCardTableDashboardProperty;
