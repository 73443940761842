import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  SkeletonCircle,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { IoMdSettings } from "react-icons/io";
import { MdAccountBalanceWallet } from "react-icons/md";

import colors from "../../Configs/colors";
import AppFormSetting from "../AppComponents/SettingComponents/AppFormSetting";
import AppFormSettingBank from "../AppComponents/SettingComponents/AppFormSettingBank";

const AppBarSettings = () => {
  var store = require("store");
  const [user, setUser] = useState([]);

  const getData = () => {
    const storedArray = store.get("userData");
    const dataUser = storedArray;
    setUser(dataUser, "ini data user");
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Box backgroundColor={colors.light} h="40vh">
        <Flex align="center" h="30%" justifyContent="center">
          <Box>
            <Image src="/propertylogo.png" maxWidth="200px" />
          </Box>
        </Flex>
        <Flex justifyContent="center">
          {user.image_user ? (
            <Avatar size="100" name="Christian Nwamba" src={user.image_user} />
          ) : (
            <SkeletonCircle size="100" />
          )}
          <Box ml="3">
            <Text fontSize={20} fontWeight="bold" mt={1}>
              {user.name_user}
            </Text>
            <Text fontSize={15} mt={1}>
              {user.email_user}
            </Text>
            <Text fontSize={15} mt={1}>
              {user.phone_number}
            </Text>
            <Text fontSize={15} mt={1}>
              {user.address_user}
            </Text>
            <Text fontSize={15} mt={1}>
              Bank {user.bank_name} {"/"} {user.account_number}
            </Text>
            <Box
              backgroundColor={colors.theme}
              w="auto"
              p={1}
              borderRadius={10}
              mt="1"
            >
              <Text
                fontSize={15}
                color={colors.light}
                align="center"
                fontWeight="bold"
              >
                {user.user_role}
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
      <Tabs isFitted variant="enclosed">
        <TabList mb="1em">
          <Tab
            _selected={{ color: colors.danger, bg: colors.light }}
            fontWeight="bold"
            fontSize="90%"
          >
            <IoMdSettings size={30} />
            Account Setting
          </Tab>
          <Tab
            _selected={{ color: colors.danger, bg: colors.light }}
            fontWeight="bold"
            fontSize="90%"
          >
            <MdAccountBalanceWallet size={30} />
            Bank Account
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AppFormSetting />
          </TabPanel>
          <TabPanel>
            <AppFormSettingBank />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default AppBarSettings;
