import React from "react";
import MainLayout from "../Components/Layouts/MainLayout";
import AppBarProperty from "../Components/AppBars/AppBarProperty";

function PropertyPage() {
  const { AppLayout } = MainLayout();

  return <>{AppLayout(AppBarProperty)}</>;
}

export default PropertyPage;
