import React, { useState, useEffect } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Heading,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import CurrencyFormat from "react-currency-format";

import { FiSearch, FiEye, FiThumbsUp } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import colors from "../../../Configs/colors";
import { getOrderIndex } from "../../../Api/Admin/apiOrders";
import { NavButton } from "../AppButtonNavBar";
import {
  MdDashboard,
  MdOutlineAdd,
  MdOutlineAddBusiness,
} from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import { IoBusiness } from "react-icons/io5";
import { doc, FieldValue, increment, serverTimestamp, setDoc } from "firebase/firestore";
import { auth, db } from "../../../Configs/firebase";
import { upload, uploadWithBytes } from "../../../Utils/image";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

function AdminAppTableTransaction() {
  let navigate = useNavigate();
  const [data, setData] = useState([]);

  const [modalTitle, setModalTitle] = useState();
  const [modalBody, setModalBody] = useState();
  const [uploadDoc, setUploadDoc] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure()

  const getTransactionData = async () => {
    const transaction = await getOrderIndex();
    console.log(transaction);
    setData(transaction);
    console.log(data, "transaction");
  };

  const handleMarkPaid = async (externalId) => {
    try {
      const docId = externalId.split("-");

      // const imgTitle = (Math.random() + 1).toString(36).substring(7);
      // const imgPath = await uploadWithBytes(imgTitle, "transaction", uploadDoc);
      // console.log(imgPath, "imgPath");

      // let imgLocation = `gs://property-a5813.appspot.com/transaction/${imgTitle}`;

      // const storage = getStorage();
      // const starsRef = ref(storage, imgLocation);
      // const url = await getDownloadURL(starsRef);
      // console.log(url, "url");

      const orderRef = await setDoc(
        doc(db, "orders", docId[4]), {
        status: "PAID",
        paid_at: serverTimestamp(),
        payment_channel: "Manual Paid",
        paid_uid: auth.currentUser.uid,
        // payment_proof: url,
      }, {
        merge: true
      })

      const updateProperty = await setDoc(
        doc(db, "property", docId[5]), {
        sold: increment(parseInt(docId[6]))
      }, {
        merge: true
      })

      console.log("orderRef: " + orderRef, "updateProperty: " + updateProperty);

    } catch (error) {
      console.log(error);
    }

  }

  const handleDetail = (externalId) => {
    const docId = externalId.split("-");
    navigate(`/admin/transaction/${docId[4]}`);
  }

  const handleModalDetail = (externalId) => {
    const docId = externalId.split("-");
    handleModal("markPaid", externalId);
  }

  const handleModal = (type, x) => {
    const dataModal = () => {
      switch (type) {
        case "markPaid":
          return (
            <>
              <FormControl>
                <FormLabel>order: {x}</FormLabel>
                <Input
                  m="2"
                  type="file"
                  placeholder="Upload image"
                  onChange={(e) => setUploadDoc(e.target.files[0])}
                />
              </FormControl>
              <Button colorScheme="blue" mr={3} onClick={() => handleMarkPaid(x)}>
                Save
              </Button>
            </>
          );

        default:
          return <h1>No project match</h1>;
      }
    };
    setModalTitle(type);
    setModalBody(dataModal);
    onOpen();
  }
  // const MemberTable = (props) => (
  //   <Box>
  //     <Table {...props} boxShadow="lg">
  //       <Thead backgroundColor={colors.theme}>
  //         <Tr>
  //           <Th>Transaction</Th>
  //           <Th>Status</Th>
  //           <Th>Users</Th>
  //           <Th>Property</Th>
  //           <Th>Lot</Th>
  //           <Th>Amount</Th>
  //           <Th>ACTION</Th>
  //         </Tr>
  //       </Thead>
  //       <Tbody>
  //         {data.map((item, index) => (
  //           <Tr key={index}>
  //             <Td>
  //               <HStack spacing="3">
  //                 <Avatar name={item.property} src={item.image} boxSize="10" />
  //                 <Box>
  //                   <Text fontWeight="medium">{item.property}</Text>
  //                   <Text color="muted">{item.date}</Text>
  //                 </Box>
  //               </HStack>
  //             </Td>

  //             <Td>
  //               <Badge
  //                 size="sm"
  //                 colorScheme={item.status === "UNPAID" ? "red" : "green"}
  //               >
  //                 {item.status}
  //               </Badge>
  //             </Td>
  //             <Td>
  //               <Text color="muted">{item.email}</Text>
  //             </Td>
  //             <Td>
  //               <Text color="muted">{item.type}</Text>
  //             </Td>
  //             <Td>
  //               <Text color="muted">{item.sold} Lot</Text>
  //             </Td>
  //             <Td>
  //               <CurrencyFormat
  //                 value={item.amount}
  //                 displayType={"text"}
  //                 thousandSeparator={true}
  //                 prefix={"IDR "}
  //               />
  //             </Td>
  //             <Td>
  //               <HStack spacing="3">
  //                 <IconButton
  //                   icon={<FiEye fontSize="1.25rem" />}
  //                   variant="ghost"
  //                   aria-label="Delete member"
  //                   colorScheme={"messenger"}
  //                   onClick={() => handleModal("markPaid", item.external_id)}
  //                 />

  //                 {item.status === "UNPAID" ? (<NavButton
  //                   onClick={onOpen}
  //                   label="Mark Paid"
  //                   bgColor={"green"}
  //                   textColor="white"
  //                   iconColor="white"
  //                   icon={FiThumbsUp} />) : (<></>)}
  //               </HStack>
  //             </Td>
  //           </Tr>
  //         ))}
  //       </Tbody>
  //     </Table>
  //   </Box>
  // );

  useEffect(() => {
    getTransactionData();
    return () => {
    };
  }, []);

  console.log(uploadDoc, "uploadDoc");

  return (
    <Box bg="bg-surface">
      <Stack spacing="5">
        <Box px={{ base: "4", md: "6" }} pt="5">
          <Stack
            direction={{ base: "column", md: "row" }}
            justify="space-between"
          >
            <Box>
              <Heading fontWeight="medium" fontSize="large">
                Transaction
              </Heading>
              <Text color="muted">All Transaction List</Text>
            </Box>
            <HStack spacing="2">
              <HStack>
                <InputGroup maxW="xs">
                  <Input placeholder="Search" />
                </InputGroup>
                <IconButton
                  icon={<FaSearch />}
                  variant="solid"
                  colorScheme="yellow"
                />
              </HStack>
              <NavButton
                label="Transaction"
                icon={MdOutlineAdd}
                aria-current="page"
                onClick={() => navigate("/admin/transaction/add")}
                bgColor="#ffd600"
              />
            </HStack>
          </Stack>
        </Box>
        <Box overflowX="auto" p={3}>
          <Box>
            <Table boxShadow="lg">
              <Thead backgroundColor={colors.theme}>
                <Tr>
                  <Th>Transaction</Th>
                  <Th>Status</Th>
                  <Th>Users</Th>
                  <Th>Property</Th>
                  <Th>Lot</Th>
                  <Th>Amount</Th>
                  <Th>ACTION</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.map((item, index) => (
                  <Tr key={index}>
                    <Td>
                      <HStack spacing="3">
                        <Avatar name={item.property} src={item.image} boxSize="10" />
                        <Box>
                          <Text fontWeight="medium">{item.property}</Text>
                          <Text color="muted">{item.date}</Text>
                        </Box>
                      </HStack>
                    </Td>

                    <Td>
                      <Badge
                        size="sm"
                        colorScheme={item.status === "UNPAID" ? "red" : "green"}
                      >
                        {item.status}
                      </Badge>
                    </Td>
                    <Td>
                      <Text color="muted">{item.email}</Text>
                    </Td>
                    <Td>
                      <Text color="muted">{item.type}</Text>
                    </Td>
                    <Td>
                      <Text color="muted">{item.sold} Lot</Text>
                    </Td>
                    <Td>
                      <CurrencyFormat
                        value={item.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IDR "}
                      />
                    </Td>
                    <Td>
                      <HStack spacing="3">
                        <IconButton
                          icon={<FiEye fontSize="1.25rem" />}
                          variant="ghost"
                          colorScheme={"messenger"}
                          onClick={() => handleDetail(item.external_id)}
                        />

                        {item.status === "UNPAID" ? (<NavButton
                          onClick={() => handleMarkPaid(item.external_id)}
                          label="Mark Paid"
                          bgColor={"green"}
                          textColor="white"
                          iconColor="white"
                          icon={FiThumbsUp} />) : (<></>)}
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
        <Box px={{ base: "4", md: "6" }} pb="5">
          <HStack spacing="3" justify="space-between">
            <Text color="muted" fontSize="sm">
              Showing 1 to 5 of 42 results
            </Text>

            <ButtonGroup
              spacing="3"
              justifyContent="space-between"
              width={{ base: "full", md: "auto" }}
              variant="secondary"
            >
              <Button>Previous</Button>
              <Button>Next</Button>
            </ButtonGroup>
          </HStack>
        </Box>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            {modalBody}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default AdminAppTableTransaction;
