import React from "react";
import AppBarAcademyDetails from "../Components/AppBars/AppBarAcademyDetails";
import MainLayout from "../Components/Layouts/MainLayout";

function AcademyPageDetails() {
  const { AppLayout } = MainLayout();

  return <>{AppLayout(AppBarAcademyDetails)}</>;
}

export default AcademyPageDetails;
