import React, { useState, useEffect } from "react";
import {
  Avatar,
  Badge,
  Box,
  Image,
  HStack,
  Stack,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Heading,
  Container,
  Flex,
  VStack,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  List,
  ListItem,
  Center,
} from "@chakra-ui/react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { MdLocalShipping } from "react-icons/md";
import CurrencyFormat from "react-currency-format";

import { auth, db } from "../../../Configs/firebase";
import { Link } from "react-router-dom";
import colors from "../../../Configs/colors";

const AppTableTransactionDetail = (props) => {
  var moment = require("moment");
  const [transaction, setTransaction] = useState([]);
  const type = props.property;

  const user = auth.currentUser.uid;

  const getData = async () => {
    let arr = [];
    const col = collection(db, "orders");
    const q = query(
      col,
      where("userID", "==", user),
      where("property", "==", type)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    setTransaction(arr);
  };

  useEffect(() => {
    getData();
    return () => {
      setTransaction([]);
    };
  }, []);

  return (
    <Container maxW={"7xl"}>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={{ base: 8, md: 10 }}>
        <Flex>
          <Image
            rounded={"md"}
            alt={"product image"}
            src={props.image}
            fit={"cover"}
            align={"center"}
            w={"100%"}
            h={{ base: "100%", sm: "400px", lg: "500px" }}
          />
        </Flex>
        <Stack spacing={{ base: 6, md: 10 }}>
          <Stack spacing="3">
            <Badge borderRadius="full" colorScheme="yellow" w="100px" p="6px">
              <Center>{props.type}</Center>
            </Badge>
            <Heading size="lg" fontWeight="medium">
              {props.property}
            </Heading>
          </Stack>

          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={"column"}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.200", "gray.600")}
              />
            }
          >
            <VStack spacing={{ base: 4, sm: 6 }}>
              <Text
                color={useColorModeValue("gray.500", "gray.400")}
                fontSize={"2xl"}
                fontWeight={"300"}
              >
                {props.property}
              </Text>
              <Text fontSize={"lg"}>{props.description}</Text>
            </VStack>
            <Box>
              <Text
                fontSize={{ base: "16px", lg: "18px" }}
                color={useColorModeValue("yellow.500", "yellow.300")}
                fontWeight={"500"}
                textTransform={"uppercase"}
                mb={"4"}
              >
                Product Details
              </Text>

              <List spacing={2}>
                <ListItem>
                  <Text as={"span"} fontWeight={"bold"}>
                    jumlah lot:
                  </Text>{" "}
                  {props.sold} lot
                </ListItem>
                <ListItem>
                  <Text as={"span"} fontWeight={"bold"}>
                    harga/amount:
                  </Text>{" "}
                  Rp. {props.amount}
                </ListItem>
                <ListItem>
                  <Text as={"span"} fontWeight={"bold"}>
                    email:
                  </Text>{" "}
                  {props.email}
                </ListItem>
                <ListItem>
                  <Text as={"span"} fontWeight={"bold"}>
                    createdat:
                  </Text>{" "}
                  {props.createat}
                </ListItem>
              </List>
            </Box>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent={"center"}>
            <MdLocalShipping />
            <Text>property details</Text>
          </Stack>
        </Stack>
      </SimpleGrid>
      <Center my={8}>
        <Text fontWeight="bold" textAlign="center" fontSize={20}>
          {" "}
          History Transaction Property
        </Text>
      </Center>
      <Box boxShadow="lg" overflowX="auto">
        <Table {...props}>
          <Thead backgroundColor={colors.theme}>
            <Tr>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text>Transaction</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>Status</Th>
              <Th>Property</Th>
              <Th>Lot</Th>
              <Th>Amount</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {transaction.map((member) => (
              <Tr key={member.id}>
                <Td>
                  <HStack spacing="3">
                    <Avatar
                      name={member.property}
                      src={member.image}
                      boxSize="10"
                    />
                    <Box>
                      <Text fontWeight="medium">{member.property}</Text>
                      <Text color="muted">{member.time}</Text>
                    </Box>
                  </HStack>
                </Td>

                <Td>
                  <Badge
                    size="sm"
                    colorScheme={member.status === "DIVIDEN" ? "green" : "red"}
                  >
                    {member.status}
                  </Badge>
                </Td>
                <Td>
                  <Text color="muted">{member.type}</Text>
                </Td>
                <Td>
                  <Text color="muted">{member.sold}</Text>
                </Td>
                <Td>
                  <CurrencyFormat
                    value={member.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"IDR "}
                  />
                </Td>
                <Td>
                  <Link to={`/transaction/${member.external_id}`}>
                    <HStack spacing="1"></HStack>
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Container>
  );
};

export default AppTableTransactionDetail;
