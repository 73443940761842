import { Box, Center, Text } from "@chakra-ui/react";
import React from "react";
import colors from "../../../Configs/colors";

function AppCardPropertyType(props) {
  return (
    <Box borderRadius="md" overflow="hidden" maxH="75" onClick={props.onClick}>
        <Box bgImage={props.image} boxSize="52"
          borderRadius="base"
          bgSize="cover"
          >
          <Center h="35%">
          <Text color={colors.dark} letterSpacing={3} fontWeight='extrabold' >{props.title}</Text>
          </Center>
        </Box>
        {/* <Image
          src={props.image}
          boxSize="52"
          borderRadius="base"
          objectFit="cover"
        /> */}
    </Box>
  );
}

export default AppCardPropertyType;
