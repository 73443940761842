import {
  Box,
  Container,
  Heading,
  HStack,
  Image,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import colors from "../../Configs/colors";

import { db } from "../../Configs/firebase";
import AppCardProperty from "../AppComponents/PropertyComponents/AppCardProperty";
import AppCardPropertyType from "../AppComponents/PropertyComponents/AppCardPropertyType";

function AppBarProperty() {
  const [filter, setFilter] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);

  const [property, setProperty] = useState([]);

  const getData = async () => {
    let arr = [];
    // if (filter === null) {
    const q = collection(db, "property");
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    setProperty(arr);
  };

  const handleClick = async (e) => {
    setFilter(e);
    let arr = [];
    const col = collection(db, "property");
    const q = query(col, where("type", "==", filter));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    setProperty(arr);
  };

  useEffect(() => {
    getData();
  }, []);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 680);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 680);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <Container maxW="container.xl" py="8" flex="1">
      <Stack
        spacing={{
          base: "8",
          lg: "6",
        }}
      >
        {console.log(filter, "ini sudah di filter")}
        <HStack
          spacing="4"
          direction="row"
          justify="space-between"
          backgroundColor={colors.theme}
          px={5}
          py={4}
          borderRadius={5}
          boxShadow="md"
        >
          <Stack spacing="1" ml={5}>
            <Heading
              size={useBreakpointValue({
                base: "xs",
                lg: "sm",
              })}
              fontWeight="Bold"
              fontSize="230%"
              letterSpacing={1}
              color={colors.dark}
            >
              Property
            </Heading>
            <Text color={colors.darklight} fontSize="80%">
              Search property to buy
            </Text>
          </Stack>
          <SimpleGrid columns={[1, null, 3]} spacing="20px">
            <Box>
              <AppCardPropertyType
                image="/rumah.jpeg"
                onClick={(e) => handleClick("kontrakan")}
                title="Kontrakan"
              />
            </Box>

            <Box>
              <AppCardPropertyType
                image="/kosan.jpeg"
                onClick={(e) => handleClick("kosan")}
                title="Kosan"
              />
            </Box>

            <Box>
              <AppCardPropertyType
                image="/ruko.webp"
                onClick={(e) => handleClick("ruko")}
                title="Ruko"
              />
            </Box>
          </SimpleGrid>
        </HStack>
        {property.map((property) => (
          <Link to={`/property/${property.property_id}`}>
            <AppCardProperty data={property} />
          </Link>
        ))}
      </Stack>
    </Container>
  );
}

export default AppBarProperty;
