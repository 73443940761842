import {
  Container,
  Flex,
  Stack,
  VStack,
  Text,
  Avatar,
  Divider,
  HStack,
  Box,
  Image,
  Button,
  List,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiGrid, FiTag } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getCourseDetail,
  getLessonsIndex,
} from "../../../Api/Admin/apiCourses";

const AppCardAcademyDetail = () => {
  const param = useParams();
  const [data, setData] = useState("");
  const [lessons, setLessons] = useState([]);
  const navigate = useNavigate();

  const getCourseData = async () => {
    const response1 = await getCourseDetail(param.id);
    setData(response1);
    console.log(response1, "getCourseData");
  };

  const getLessonsData = async () => {
    const response2 = await getLessonsIndex(param.id);
    setLessons(response2);
    console.log(response2, "getLessonsData");
  };

  useEffect(() => {
    getCourseData();
    getLessonsData();
    return () => {
      setData("");
      setLessons([]);
    };
  }, []);

  return (
    <Container maxW="container.xl" py="8" flex="1">
      <Stack
        spacing={{
          base: "8",
          lg: "6",
        }}
      >
        <Stack spacing={5}>
          {/* Hero */}
          <Flex
            w={"full"}
            h={{ base: "13rem", lg: "20rem" }}
            rounded="lg"
            boxShadow="md"
            backgroundImage={
              data.featureImage === ""
                ? data.featureImage
                : "https://realmomjobs.com/wp-content/themes/thrive-theme/inc/assets/images/featured_image.png"
            }
            backgroundSize={"cover"}
            backgroundPosition={"center center"}
          >
            <VStack
              w={"full"}
              justify={"center"}
              rounded="lg"
              align="flex-start"
              px={{ base: 4, md: 8 }}
              bgGradient={"linear(to-r, blackAlpha.600, transparent)"}
            >
              <Stack
                maxW={"2xl"}
                align={"flex-start"}
                spacing={{ base: "2rem", md: "4rem" }}
              >
                <Text
                  color="#ffd600"
                  textShadow="dark-lg"
                  fontWeight={700}
                  lineHeight={1.2}
                  fontSize={{ base: "2xl", md: "4xl" }}
                >
                  {data.course_name}
                </Text>
                {/* <Stack spacing={5} align="center" direction={"row"}>
                  <Avatar></Avatar>
                  <Text color="white" fontWeight="semibold">
                    Username{" "}
                  </Text>
                  <Text color="white" fontSize="0.8rem">
                    August 10, 2020
                  </Text>
                </Stack> */}
              </Stack>
            </VStack>
          </Flex>

          <Flex
            flexDir={{ base: "column-reverse", lg: "row" }}
            align={{ base: "center", lg: "flex-start" }}
            p={5}
          >
            {/* Description Course */}
            <Stack
              width={{ base: "90%", lg: "60%" }}
              spacing="2rem"
              mr={{ base: "0", lg: "6rem" }}
            >
              <Text fontSize="xl" fontWeight="medium">
                Description
              </Text>
              <Text color="gray">{data.course_description}</Text>
              <Divider />
              <Box>
                {lessons.map((item, index) => (
                  <Box
                    key={index}
                    onClick={() => navigate(`${item.lesson_id}`)}
                    cursor="pointer"
                    px="2rem"
                    py="1rem"
                    bg="yellow.200"
                    borderRadius="lg"
                    _hover={{ bg: "#ffd600" }}
                    mt="1rem"
                  >
                    <Text fontSize="xl" fontWeight="medium">
                      {item.lesson_name}
                    </Text>
                  </Box>
                ))}
              </Box>
              <Text fontWeight="Bold">COURSE INSTRUCTOR</Text>

              <HStack spacing={5} border="1px solid gray" p={5} rounded="lg">
                <Avatar />
                <Box>
                  <Text fontWeight="bold">INSTRUCTOR</Text>
                  <Text color="gray">{data.course_instructor}</Text>
                </Box>
              </HStack>
            </Stack>
            {/* Right Content */}
            <Stack
              spacing={2}
              pos="relative"
              rounded="lg"
              top={{ base: "0", lg: "-13rem" }}
              mb={{ base: "3rem", lg: "0" }}
            >
              <Stack
                width={"20rem"}
                bg="white"
                shadow="lg"
                rounded="lg"
                p={5}
                spacing={5}
                _hover={{ transform: "translateY(-1px)" }}
              >
                <Image
                  src={
                    data.course_image
                      ? data.course_image
                      : "https://realmomjobs.com/wp-content/themes/thrive-theme/inc/assets/images/featured_image.png"
                  }
                />
                <HStack justify="center">
                  <Avatar size="xs" />
                  <Text>+{data.course_enroll} enrolled</Text>
                </HStack>
                <Text fontSize="0.8rem" fontWeight="bold">
                  COURCE INFORMATION
                </Text>
                <HStack>
                  <FiGrid />
                  <Text>
                    Categories:
                    <Link to="/courses/category"> data category</Link>
                  </Text>
                </HStack>
                <HStack>
                  <FiTag />
                  <Text>
                    Tags: <Link to="/courses/category">tags</Link>
                  </Text>
                </HStack>
              </Stack>
            </Stack>
          </Flex>
        </Stack>
      </Stack>
    </Container>
  );
};

export default AppCardAcademyDetail;
