import {
  Box,
  Container,
  Grid,
  Heading,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  MdPayments,
  MdOutlineRealEstateAgent,
  MdOutlineBrightness4,
} from "react-icons/md";
import { IoBusiness } from "react-icons/io5";
import { FaUser } from 'react-icons/fa';

import { useNavigate, Outlet } from "react-router-dom";
import { NavButton } from "../AppComponents/AppButtonNavBar";
import colors from "../../Configs/colors";

function AppBarAdmin() {
  let navigate = useNavigate();
  const [isDesktop, setDesktop] = useState(window.innerWidth > 680);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 680);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <Container maxW="container.xl" py="4" flex="1" mt={4}>
      <Stack
        spacing={{
          base: "8",
          lg: "6",
        }}
      >
        <HStack spacing="4" direction="row" justify="flex-start" backgroundColor={colors.theme} boxShadow='md' py={5} px={3} borderRadius={5} >
          {isDesktop ? (
            <>
              <Stack spacing="1" ml={5}>
            <Heading
              fontWeight="Bold"
              fontSize='230%'
              letterSpacing={1}
              color={colors.dark}
            >
              Administrator
            </Heading>
            <Text color={colors.darklight} fontSize='80%' >Manage your system</Text>
          </Stack>
              <Box
                bgColor="black"
                w="auto"
                h="65px"
                rounded="10px"
                display="flex"
                p="3"
              >
                <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                  <NavButton
                    label="Account"
                    icon={FaUser}
                    bgColor="#ffd600"
                    textColor="black"
                    iconColor="black"
                    onClick={() => navigate("/admin/add")}
                  />
                  <NavButton
                    label="Property"
                    icon={IoBusiness}
                    bgColor="#ffd600"
                    textColor="black"
                    iconColor="black"
                    onClick={() => navigate("/admin/property")}
                  />
                  <NavButton
                    label="Transaction"
                    icon={MdPayments}
                    bgColor="#ffd600"
                    textColor="black"
                    iconColor="black"
                    onClick={() => navigate("/admin/transaction")}
                  />
                  <NavButton
                    label="Foreclosure"
                    icon={MdOutlineRealEstateAgent}
                    bgColor="red"
                    textColor="white"
                    iconColor="white"
                    onClick={() => navigate("/admin/foreclosure")}
                  />
                  <NavButton
                    label="KPR Syariah"
                    icon={MdOutlineBrightness4}
                    bgColor="green"
                    textColor="white"
                    iconColor="white"
                    onClick={() => navigate("/admin/kprsyariah")}
                  />
                </Grid>
              </Box>
            </>
          ) : (
            <>
              <Box w="auto">
                <Heading fontWeight="medium" fontSize="large">
                  ADMINISTRATOR
                </Heading>
                <Text color="muted">Manage Your</Text>
                <Text color="muted">System</Text>
                <Box
                  bgColor="black"
                  w="full"
                  h="65px"
                  rounded="10px"
                  display="flex"
                  p="3"
                  mt="4"
                >
                  <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                  <NavButton
                    label="Account"
                    icon={FaUser}
                    bgColor="#ffd600"
                    textColor="black"
                    iconColor="black"
                    onClick={() => navigate("/admin/add")}
                  />
                    <NavButton
                      icon={IoBusiness}
                      bgColor="#ffd600"
                      textColor="black"
                      iconColor="black"
                      onClick={() => navigate("/admin/property")}
                    />
                    <NavButton
                      icon={MdPayments}
                      bgColor="#ffd600"
                      textColor="black"
                      iconColor="black"
                      onClick={() => navigate("/admin/transaction")}
                    />
                    <NavButton
                      icon={MdOutlineRealEstateAgent}
                      bgColor="#ffd600"
                      textColor="black"
                      iconColor="black"
                      onClick={() => navigate("/admin/foreclosure")}
                    />
                    <NavButton
                      icon={MdOutlineBrightness4}
                      bgColor="#ffd600"
                      textColor="black"
                      iconColor="black"
                      onClick={() => navigate("/admin/kprsyariah")}
                    />
                  </Grid>
                </Box>
              </Box>
            </>
          )}
        </HStack>
        <Outlet />
      </Stack>
    </Container>
  );
}

export default AppBarAdmin;
