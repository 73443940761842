import React from "react";

import AppBarTransactionDetail from "../Components/AppBars/AppBarTransactionDetail";
import MainLayout from "../Components/Layouts/MainLayout";

function TransactionDetailPage() {
  const { AppLayout } = MainLayout();

  return <>{AppLayout(AppBarTransactionDetail)}</>;
}

export default TransactionDetailPage;
