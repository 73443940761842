import {
  Container,
  Stack,
  Image,
  Heading,
  FormControl,
  Text,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Link,
  HStack,
  useBreakpointValue,
  Box,
  SimpleGrid,
  Select,
} from "@chakra-ui/react";
import {
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  sendEmailVerification,
} from "firebase/auth";
import Swal from "sweetalert2";

import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import colors from "../../../Configs/colors";
import { auth, db } from "../../../Configs/firebase";

const AppFormSetting = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [user, setUser] = useState("");

  let navigate = useNavigate();

  return (
    <Container maxW="4xl" py={{ base: "6", md: "12" }}>
      <Stack spacing="8">
        <Stack spacing="6">
          <Stack spacing="3">
            <Heading
              size={useBreakpointValue({ base: "xs", md: "sm" })}
              fontSize={20}
            >
              Setting Account
            </Heading>
          </Stack>
        </Stack>
        <SimpleGrid columns={[1, null, 2]} spacingX={7}>
          <Stack spacing={5}>
            <FormControl isRequired>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                id="name"
                type="name"
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Input
                id="password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormHelperText color={colors.darklight} fontSize="50%">
                At least 8 characters long
              </FormHelperText>
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="address">Address</FormLabel>
              <Input
                id="address"
                type="address"
                onChange={(e) => setAddress(e.target.value)}
              />
            </FormControl>
          </Stack>
          {/* blabla */}
          <Stack spacing={5}>
            <FormControl isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="confirmpassword">Confirm Password</FormLabel>
              <Input
                id="confirmpassword"
                type="password"
                onChange={(e) => setConfirmationPassword(e.target.value)}
              />
              <FormHelperText color={colors.darklight} fontSize="50%">
                At least 8 characters long
              </FormHelperText>
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
              <Input
                id="phoneNumber"
                type="number"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormControl>
          </Stack>
        </SimpleGrid>
        {/* <SimpleGrid spacing="4" columns={[1, null, 2]} w="50%">
          <Button
            onClick={() => onHandleRegister()}
            backgroundColor={colors.theme}
            color="#ffff"
          >
            Create Account
          </Button>
          <Button
            colorScheme="red"
            border="1px"
            onClick={() => navigate("/admin/add")}
          >
            Cancel
          </Button>
        </SimpleGrid> */}
        {/* <HStack justify="end" spacing="1">
          <Text fontSize="sm" color="muted">
            Deactive Your Account
          </Text>
          <Link to="/login">
            <Button
              variant="link"
              colorScheme="red"
              size="sm"
              onClick={() => handleDelete()}
            >
              Deactive
            </Button>
          </Link>
        </HStack> */}
      </Stack>
    </Container>
  );
};

export default AppFormSetting;
