import { StarIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Heading,
  HStack,
  Image,
  Progress,
  Text,
  useColorModeValue as mode,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import colors from "../../../Configs/colors";

function AppCardProperty(props) {
  const formatPrice = (value, opts = {}) => {
    const { locale = "en-US", currency = "USD" } = opts;
    const formatter = new Intl.NumberFormat(locale, {
      currency,
      style: "currency",
      maximumFractionDigits: 2,
    });
    return formatter.format(value);
  };

  const PriceTag = (props) => {
    const {
      price,
      currency,
      salePrice,
      rootProps,
      priceProps,
      salePriceProps,
    } = props;
    return (
      <HStack spacing="1" {...rootProps}>
        <Price isOnSale={!!salePrice} textProps={priceProps}>
          {formatPrice(price, {
            currency,
          })}
        </Price>
        {salePrice && (
          <SalePrice {...salePriceProps}>
            {formatPrice(salePrice, {
              currency,
            })}
          </SalePrice>
        )}
      </HStack>
    );
  };

  const Price = (props) => {
    const { isOnSale, children, textProps } = props;
    const defaultColor = mode("gray.700", "gray.400");
    const onSaleColor = mode("gray.400", "gray.700");
    const color = isOnSale ? onSaleColor : defaultColor;
    return (
      <Text
        as="span"
        fontWeight="medium"
        color={color}
        textDecoration={isOnSale ? "line-through" : "none"}
        {...textProps}
      >
        {children}
      </Text>
    );
  };

  const SalePrice = (props) => (
    <Text
      as="span"
      fontWeight="semibold"
      color={mode("gray.800", "gray.100")}
      {...props}
    />
  );

  const [isDesktop, setDesktop] = useState(window.innerWidth > 680);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 680);
  };

  const property = props.data;

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div>
      {isDesktop ? (
        <Box
          display="flex"
          maxW="auto"
          maxH="200"
          borderRadius="lg"
          overflow="hidden"
          justifyContent="left"
          borderWidth="1px"
          backgroundColor={colors.light}
          boxShadow="lg"
        >
          <Box minW="120px">
            <Image src={property.imageUrl} boxSize="200" objectFit="cover" />
          </Box>
          <Box p="10" maxH="200" w="600px" backgroundColor={colors.light}>
            <Box display="flex" maxHeight="auto">
              <Badge borderRadius="full" px="2" colorScheme="yellow">
                {property.type}
              </Badge>
            </Box>

            <Box
              mt="1"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              fontSize="large"
            >
              {property.name}
            </Box>

            <Box>
              <PriceTag
                price={property.price}
                currency="IDR"
                rootProps={{
                  fontSize: "md",
                }}
              />

              <Box as="span" color="gray.600" fontSize="sm" />
            </Box>

            <Box mt="2">
              <Progress
                size="sm"
                max={property.lot}
                value={property.sold}
                colorScheme="yellow"
                hasStripe={true}
                isAnimated={true}
                shadow="base"
                rounded={3}
              />
              <HStack mt={3}>
                <Text color="red" fontWeight="semibold" fontSize="md">
                  {property.lot - property.sold} Lot Tersisa
                </Text>
              </HStack>
            </Box>
          </Box>

          <Box p="10" maxH="200" maxW="full">
            <VStack spacing="20px">
              <HStack justifyContent="space-around">
                <Box
                  w="150px"
                  h="50px"
                  backgroundColor={colors.theme}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={10}
                >
                  <Heading
                    color="gray.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="xs"
                    textTransform="uppercase"
                  >
                    Harga per Lot
                  </Heading>
                  <PriceTag
                    price={property.lotPrice}
                    currency="IDR"
                    rootProps={{
                      fontSize: "sm",
                    }}
                  />
                </Box>
                <Box
                  maxHeight="auto"
                  w="150px"
                  h="50px"
                  backgroundColor={colors.theme}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={10}
                >
                  <Heading
                    color="gray.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="xs"
                    textTransform="uppercase"
                  >
                    Jumlah Lot
                  </Heading>
                  <Text
                    fontWeight="semibold"
                    fontSize="sm"
                    textTransform="uppercase"
                  >
                    {property.lot}
                  </Text>
                </Box>
              </HStack>

              <HStack justifyContent="space-around">
                <Box
                  maxHeight="auto"
                  w="150px"
                  h="50px"
                  backgroundColor={colors.theme}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={10}
                >
                  <Heading
                    color="gray.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="xs"
                    textTransform="uppercase"
                  >
                    Periode Dividen
                  </Heading>
                  <Text
                    fontWeight="semibold"
                    fontSize="xs"
                    textTransform="uppercase"
                    mt={1}
                  >
                    {property.periode +
                      " Bulan | " +
                      property.fullPeriode / 12 +
                      " Tahun"}
                  </Text>
                </Box>

                <Box
                  maxHeight="auto"
                  w="150px"
                  h="50px"
                  backgroundColor={colors.theme}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={10}
                >
                  <Heading
                    color="gray.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="xs"
                    textTransform="uppercase"
                  >
                    Estimasi Dividen
                  </Heading>
                  <Text
                    mt={1}
                    fontWeight="semibold"
                    fontSize="xs"
                    textTransform="uppercase"
                  >
                    {property.dividen + " % / Tahun"}
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </Box>
        </Box>
      ) : (
        <Box maxW="md" borderWidth="1px" borderRadius="lg" overflow="hidden">
          <Image src={property.imageUrl} />

          <Box p="6">
            <Box display="flex" alignItems="baseline">
              <Badge borderRadius="full" px="2" colorScheme="teal">
                {property.type}
              </Badge>
            </Box>

            <Box
              mt="1"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              isTruncated
            >
              {property.name}
            </Box>

            <Box>
              {property.price}
              <Box as="span" color="gray.600" fontSize="sm" />
            </Box>
            <Box>
              <Progress
                size="sm"
                max={property.lot}
                value={property.sold}
                colorScheme="yellow"
                hasStripe={true}
                isAnimated={true}
              />
            </Box>

            <Box display="flex" mt="2" alignItems="center">
              {Array(5)
                .fill("")
                .map((_, i) => (
                  <StarIcon
                    key={i}
                    color={i < property.rating ? "yellow.400" : "gray.300"}
                  />
                ))}
              {/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
                {property.reviewCount} reviews
              </Box> */}
            </Box>
          </Box>

          <Box p="10" maxH="200" maxW="full">
            <VStack spacing="20px">
              <HStack justifyContent="space-around">
                <Box
                  w="150px"
                  h="50px"
                  backgroundColor={colors.theme}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={10}
                >
                  <Heading
                    color="gray.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="xs"
                    textTransform="uppercase"
                  >
                    Harga per Lot
                  </Heading>
                  <PriceTag
                    price={property.lotPrice}
                    currency="IDR"
                    rootProps={{
                      fontSize: "sm",
                    }}
                  />
                </Box>

                <Box
                  maxHeight="auto"
                  w="150px"
                  h="50px"
                  backgroundColor={colors.theme}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={10}
                >
                  <Heading
                    color="gray.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="xs"
                    textTransform="uppercase"
                  >
                    Jumlah Lot
                  </Heading>
                  <Text
                    fontWeight="semibold"
                    fontSize="sm"
                    textTransform="uppercase"
                  >
                    {property.lot}
                  </Text>
                </Box>
              </HStack>

              <HStack justifyContent="space-around">
                <Box
                  maxHeight="auto"
                  w="150px"
                  h="50px"
                  backgroundColor={colors.theme}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={10}
                >
                  <Heading
                    color="gray.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="xs"
                    textTransform="uppercase"
                  >
                    Periode Dividen
                  </Heading>
                  <Text
                    fontWeight="semibold"
                    fontSize="sm"
                    textTransform="uppercase"
                  >
                    {property.periode +
                      " Bulan | " +
                      property.fullPeriode / 12 +
                      " Tahun"}
                  </Text>
                </Box>

                <Box
                  maxHeight="auto"
                  w="150px"
                  h="50px"
                  backgroundColor={colors.theme}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={10}
                >
                  <Heading
                    color="gray.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="xs"
                    textTransform="uppercase"
                  >
                    Estimasi Dividen
                  </Heading>
                  <Text
                    fontWeight="semibold"
                    fontSize="sm"
                    textTransform="uppercase"
                  >
                    {property.dividen + " % / Tahun"}
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </Box>
        </Box>
      )}
    </div>
  );
}

export default AppCardProperty;
