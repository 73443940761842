// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: "AIzaSyCtFL8YNNRUjy4QLTE5FRIUyYUdtnerh_s",
  // authDomain: "propertycoid-app.firebaseapp.com",
  // databaseURL:
  //   "https://propertycoid-app-default-rtdb.asia-southeast1.firebasedatabase.app",
  // projectId: "propertycoid-app",
  // storageBucket: "propertycoid-app.appspot.com",
  // messagingSenderId: "7084681926",
  // appId: "1:7084681926:web:271e829d2d63494ec185c7",
  // measurementId: "G-JR5D9CZK2P",
  apiKey: "AIzaSyBkEG_3XDtXUsCTKGrlptneo5320TVH_co",
  authDomain: "property-a5813.firebaseapp.com",
  databaseURL:
    "https://property-a5813-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "property-a5813",
  storageBucket: "property-a5813.appspot.com",
  messagingSenderId: "329660306718",
  appId: "1:329660306718:web:b74d5739d5ea95d2e7250f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, app, storage };
