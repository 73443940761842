import React, { useState } from "react";
import { Navigate } from "react-router-dom";

const ProtectRoutes = (props) => {
  var useAuth = false;
  var store = require("store");

  const userData = store.get("userData");
  const userRole = userData.user_role;

  if (userRole === "admin") useAuth = true;
  if (userRole === "super_admin") useAuth = true;
  if (userRole === "user") useAuth = false;
  if (userRole === "user_course") useAuth = false;

  if (!useAuth) return <Navigate to="/attention" />;

  return props.children;
};

export default ProtectRoutes;
