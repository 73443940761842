import { Box, Container, Heading, HStack, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AppCardPropertyDetail from "../AppComponents/PropertyComponents/AppCardPropertyDetail";

function AppBarPropertyDetail() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 680);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 680);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <Container maxW="container.xl" py="8" flex="1">
      <Stack
        spacing={{
          base: "3",
          lg: "6",
        }}
      >
        <HStack spacing="4" direction="row" justify="space-between"></HStack>
        <AppCardPropertyDetail />
      </Stack>
    </Container>
  );
}

export default AppBarPropertyDetail;
