import React from "react";
import { Box } from "@chakra-ui/react";

import AppComingSoon from "../../Components/AppComponents/AppComingSoon";

function AdminForeclosurePage() {
  return <Box>
    <AppComingSoon/>
  </Box>;
}

export default AdminForeclosurePage;
