import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  startAfter,
  limit,
  endBefore,
} from "firebase/firestore";
import { db } from "../../Configs/firebase";

const getCurrentUser = async (uid) => {
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
  } else {
    console.log("No such document!");
  }
  return docSnap.data();
};

export { getCurrentUser };
