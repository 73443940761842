import React from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";

function AppAlertCard(props) {
  return (
    <Alert status={props.status}>
      <AlertIcon />
      <AlertTitle mr={2}>{props.title}</AlertTitle>
      <AlertDescription>{props.message}</AlertDescription>
    </Alert>
  );
}

export default AppAlertCard;
