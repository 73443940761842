import {
  Avatar,
  Box,
  HStack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Link,
  Stack,
} from "@chakra-ui/react";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";

import colors from "../../../Configs/colors";
import { db } from "../../../Configs/firebase";

const AppCardTableDashboardTransaction = (props) => {
  var moment = require("moment");
  const [transaction, setTransaction] = useState([]);

  const getData = async () => {
    let arr = [];
    const col = collection(db, "orders");
    const q = query(col, orderBy("created_at", "desc"), limit(5));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    setTransaction(arr);
  };

  useEffect(() => {
    getData();
    return () => {
      setTransaction([]);
    };
  }, []);

  const MemberTable = (props) => (
    <Table {...props} boxShadow="lg">
      <Thead backgroundColor={colors.theme}>
        <Tr>
          <Th>
            <HStack spacing="3">
              <HStack spacing="1">
                <Text>Transaction</Text>
              </HStack>
            </HStack>
          </Th>
          <Th>Property</Th>
          <Th>Lot</Th>
          <Th>Amount</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {transaction.map((member, index) => (
          <Tr key={index}>
            <Td>
              <HStack spacing="1">
                <Avatar
                  name={member.property}
                  src={member.image}
                  boxSize="10"
                />
                <Box>
                  <Text fontWeight="medium">{member.property}</Text>
                  <Text color="muted">{member.time}</Text>
                </Box>
              </HStack>
            </Td>
            <Td>
              <Text color="muted">{member.type}</Text>
            </Td>
            <Td>
              <Text color="muted">{member.sold}</Text>
            </Td>
            <Td>
              <CurrencyFormat
                value={member.amount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"IDR "}
              />
            </Td>
            <Td>
              <Link to={`/transaction/${member.external_id}`}></Link>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <Box bg="bg-surface">
      <Stack spacing="5">
        <Box px={{ base: "4", md: "6" }} pt="5">
          <Stack
            direction={{ base: "column", md: "row" }}
            justify="space-between"
          >
            <Box fontSize={18} fontWeight="bold" letterSpacing={2}>
              5 New Transaction
            </Box>
          </Stack>
        </Box>
        <Box overflowX="auto" boxShadow="lg" rounded={10}>
          <MemberTable />
        </Box>
      </Stack>
    </Box>
  );
};

export default AppCardTableDashboardTransaction;
