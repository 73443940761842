import { Button, HStack, Icon, Text } from "@chakra-ui/react";
import * as React from "react";

export const NavButton = (props) => {
  const { icon, label, bgColor, textColor, iconColor, ...buttonProps } = props;
  return (
    <Button
      variant="ghost-on-accent"
      justifyContent="start"
      {...buttonProps}
      bgColor={bgColor}
    >
      <HStack spacing="3">
        <Icon as={icon} boxSize="5" color={iconColor} />
        <Text textColor={textColor}>{label}</Text>
      </HStack>
    </Button>
  );
};
