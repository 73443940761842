import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useColorModeValue,
  Heading,
  Center,
  Progress,
} from "@chakra-ui/react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { IoAddCircleSharp } from "react-icons/io5";
import { FiSearch, FiEye } from "react-icons/fi";

import { NavButton } from "../AppButtonNavBar";
import colors from "../../../Configs/colors";
import { getPropertyIndex } from "../../../Api/Admin/apiProperty";

function AdminAppTableProperty() {
  let navigate = useNavigate();
  const [data, setData] = useState([]);

  // ..Api/Admin/apiProperty.js
  const getPropertyData = async () => {
    const property = await getPropertyIndex();
    console.log(property);
    setData(property);
  };

  useEffect(() => {
    getPropertyData();
    return () => {};
  }, []);

  const MemberTable = (props) => (
    <Table {...props}>
      <Thead backgroundColor={colors.theme}>
        <Tr>
          <Th>
            <HStack spacing="3">
              <HStack spacing="1">
                <Text>Transaction</Text>
              </HStack>
            </HStack>
          </Th>
          <Th>Status</Th>
          <Th>Property</Th>
          <Th>Lot</Th>
          <Th>Amount</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((member, index) => (
          <Tr key={index}>
            <Td>
              <HStack spacing="3">
                <Avatar name={member.name} src={member.imageUrl} boxSize="10" />
                <Box w={240}>
                  <Text fontWeight="medium">{member.name}</Text>
                  <Text color="muted">
                    {member.sold + "/" + member.lot} Lot
                  </Text>
                  <Progress max={member.lot} value={member.sold} />
                </Box>
              </HStack>
            </Td>

            <Td>
              <Badge
                size="sm"
                colorScheme={member.status === "active" ? "green" : "red"}
              >
                {member.status}
              </Badge>
            </Td>
            <Td>
              <Text color="muted">{member.type}</Text>
            </Td>
            <Td>
              <Text color="muted">{member.sold + "/" + member.lot} Lot</Text>
            </Td>
            <Td>
              <Text color="muted">IDR {member.lotPrice * member.sold}</Text>
            </Td>
            <Td>
              <HStack spacing="1">
                <Link to={`/admin/property/${member.property_id}`}>
                  <IconButton
                    icon={<FiEye fontSize="1.25rem" />}
                    variant="ghost"
                    aria-label="Delete member"
                  />
                </Link>
              </HStack>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <Box bg="bg-surface">
      <Stack spacing="5">
        <Box px={{ base: "4", md: "6" }} pt="5">
          <Stack
            direction={{ base: "column", md: "row" }}
            justify="space-between"
          >
            <NavButton
              label="Property"
              icon={IoAddCircleSharp}
              onClick={() => navigate("/admin/property/new")}
              color="black"
              bgColor="yellow.300"
            />
            <InputGroup maxW="xs">
              <InputLeftElement pointerEvents="none">
                <Icon as={FiSearch} color="muted" boxSize="5" />
              </InputLeftElement>
              <Input placeholder="Search" />
            </InputGroup>
          </Stack>
        </Box>
        <Box overflowX="auto" boxShadow="lg">
          <MemberTable />
        </Box>
        {/* <Box px={{ base: "4", md: "6" }} pb="5">
          <HStack spacing="3" justify="space-between">
            <Text color="muted" fontSize="sm">
              Showing 1 to 5 of 42 results
            </Text>

            <ButtonGroup
              spacing="3"
              justifyContent="space-between"
              width={{ base: "full", md: "auto" }}
              variant="secondary"
            >
              <Button>Previous</Button>
              <Button>Next</Button>
            </ButtonGroup>
          </HStack>
        </Box> */}
      </Stack>
    </Box>
  );
}

export default AdminAppTableProperty;
