import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {useState, useEffect} from 'react'
import {app} from "./firebase"

const auth = getAuth(app);

function useAuthentication() {
    const [user, setUser] = useState("");

    useEffect(() => {
        const unsubscibeFromAuthStatusChanged = onAuthStateChanged(auth, (user) => {
        if(user) {
            setUser(user);
        }
        else {
            setUser();
        }
});
  return unsubscibeFromAuthStatusChanged;
}, []);

return user;
}

export default useAuthentication;