import { Box } from "@chakra-ui/react";
import React from "react";
import { AdminAppFormAddProperty } from "../../Components/AppComponents/AdminComponents/AdminAppFormAddProperty";

function AdminAddPropertyPage() {
  return (
    <Box w="full" p="5">
      <AdminAppFormAddProperty />
    </Box>
  );
}

export default AdminAddPropertyPage;
