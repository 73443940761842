import * as React from "react";
import {
  Box,
  Stack,
  StackDivider,
  Text,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  useColorModeValue,
  InputGroup,
  InputLeftElement,
  Center,
  HStack,
  Icon,
  Square,
  VStack,
  Textarea,
} from "@chakra-ui/react";
import { FiUploadCloud } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import Swal from "sweetalert2";

import { db } from "../../../Configs/firebase";
import AppAlertCard from "../AppAlertCard";

function AdminEditProperty() {
  let navigate = useNavigate();
  const param = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const update = queryParams.get("update");

  const [propertyName, setPropertyName] = React.useState("");
  const [propertyType, setPropertyType] = React.useState("");
  const [propertyLotPrice, setPropertyLotPrice] = React.useState("");
  const [propertyLot, setPropertyLot] = React.useState("");
  const [propertyDividen, setPropertyDividen] = React.useState("");
  const [propertyPeriode, setPropertyPeriode] = React.useState("");
  const [propertyImage, setPropertyImage] = React.useState("");
  const [propertyDescription, setPropertyDescription] = React.useState("");
  const [savingSpinner, setSavingSpinner] = React.useState(false);

  const getData = async () => {
    const docRef = doc(db, "property", param.id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      const data = docSnap.data();
      setPropertyName(data.name);
      setPropertyType(data.type);
      setPropertyLotPrice(data.lotPrice);
      setPropertyLot(data.lot);
      setPropertyDividen(data.dividen);
      setPropertyPeriode(data.periode);
      setPropertyImage(data.imageUrl);
      setPropertyDescription(data.description);
    } else {
      console.log("No such document!");
      navigate("/404", { replace: true });
    }
  };

  const handleSave = async () => {
    setSavingSpinner(true);
    try {
      const docRef = await updateDoc(doc(db, "property", param.id), {
        name: propertyName,
        type: propertyType,
        lotPrice: propertyLotPrice,
        lot: propertyLot,
        price: propertyLot * propertyLotPrice,
        sold: 0,
        dividen: propertyDividen,
        periode: propertyPeriode,
        shown: true,
        imageUrl: propertyImage,
        description: propertyDescription,
        approved: false,
        timestamp: serverTimestamp(),
      })
        .then(() => {
          setSavingSpinner(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Property has been saved",
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .then(() => {
          navigate("/admin/property");
        });
      console.log("Document written with ID: ", docRef);
      setSavingSpinner(false);
      navigate("/admin/property?update=success", { replace: true });
    } catch (e) {
      console.log("Error adding document: ", e);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Stack spacing="5" divider={<StackDivider />}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box flexShrink={0}>
          <Text fontSize="lg" fontWeight="medium">
            Add Property
          </Text>
          <Text color="muted" fontSize="sm">
            List your property for sale
          </Text>
        </Box>
        {update ? (
          <AppAlertCard
            status="success"
            title="Congratulation"
            message="You have updated a new product"
          />
        ) : (
          <></>
        )}
        <Box
          as="form"
          bg="bg-surface"
          boxShadow={useColorModeValue("sm", "sm-dark")}
          borderRadius="lg"
          maxW="3xl"
        >
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl id="name">
                <FormLabel>Property Name</FormLabel>
                <Input
                  placeholder={propertyName}
                  onChange={(e) => setPropertyName(e.target.value)}
                />
              </FormControl>
              <FormControl id="type">
                <FormLabel>Property Type</FormLabel>
                <Select
                  placeholder={propertyType}
                  onChange={(e) => setPropertyType(e.target.value)}
                >
                  <option value="kontrakan">Kontrakan</option>
                  <option value="ruko">Ruko</option>
                  <option value="kosan">Kosan</option>
                </Select>
              </FormControl>
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl id="image">
                <FormLabel>Featured Image Link</FormLabel>
                <Input
                  placeholder={propertyImage}
                  type="imagelink"
                  // multiple
                  onChange={(e) => setPropertyImage(e.target.value)}
                />
                {/* <AppInputImage onChange={(e) => onImageUpload(e)} img={imagePreview} /> */}
              </FormControl>
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl id="price">
                <FormLabel>Price</FormLabel>
                <Input
                  placeholder={propertyLotPrice}
                  onChange={(e) => setPropertyLotPrice(e.target.value)}
                />
              </FormControl>
              <FormControl id="lot">
                <FormLabel>Lot</FormLabel>
                <Input
                  placeholder={propertyLot}
                  onChange={(e) => setPropertyLot(e.target.value)}
                />
              </FormControl>
              <FormControl id="priode">
                <FormLabel>Periode Dividen (Bulan)</FormLabel>
                <Input
                  placeholder={propertyPeriode}
                  onChange={(e) => setPropertyPeriode(e.target.value)}
                />
              </FormControl>
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl id="total price">
                <FormLabel>Total Price</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                    children="Rp"
                  />
                  <Input
                    placeholder={propertyLot * propertyLotPrice}
                    isDisabled={true}
                    onChange={(e) => setPropertyLot(e.target.value)}
                  />
                </InputGroup>
              </FormControl>
              <FormControl id="Dividen">
                <FormLabel>Dividen</FormLabel>
                <Input
                  placeholder={propertyDividen}
                  onChange={(e) => setPropertyDividen(e.target.value)}
                />
              </FormControl>
            </Stack>
            <FormControl>
              <FormLabel>Deskripsi</FormLabel>
              <Textarea
                placeholder={propertyDescription}
                onChange={(e) => setPropertyDescription(e.target.value)}
              />
            </FormControl>
          </Stack>
          <Divider />
          <Flex direction="row-reverse" py="4" px={{ base: "4", md: "6" }}>
            <Button
              variant="primary"
              bgColor="#ffd600"
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </Flex>
        </Box>
      </Stack>
    </Stack>
  );
}

export default AdminEditProperty;
