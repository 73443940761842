import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";

function TransactionPaymentPage() {
  return (
    <Flex justifyContent="center">
      <Stack mt={100} p={5}>
        <Box mb={5}>
          <Box display="flex" justifyContent="center">
            <Image src="/propertylogo.png" maxWidth="300px" />
          </Box>
          <Box textAlign="center" mt={3}>
            <Text>Terima Kaih, Pesananmu Sudah Berhasil Dibuat</Text>
            <Text>
              Selanjutnya, Silahkan Lakukan Pembayaran ke rekening dibawah ini
            </Text>
          </Box>
        </Box>
        <Box p={5} shadow="base" rounded={10} bgColor="#ffd600">
          <Box display="flex" justifyContent="center">
            <Image
              src="https://ik.imagekit.io/7j3gwo1p1/BCA_LOGO_f-be2zjVK.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653288034502"
              maxWidth="200px"
            />
          </Box>
          <Box textAlign="center" mt={7}>
            <Text>Nama Rekening:</Text>
            <Text fontWeight="semibold">PT Edrus Safta Anugerah</Text>
          </Box>
          <Box textAlign="center" mt={3}>
            <Text>No Rekening:</Text>
            <Text fontWeight="semibold"> 593-107-0101 </Text>
          </Box>
        </Box>
        <br />
        <Button shadow="base" colorScheme="green" onClick={() => window.open(`https://wa.link/j3t05r`, '_blank', 'noopener,noreferrer')}>
          Konfirmasi Pembayaran
        </Button>
      </Stack>
    </Flex>
  );
}

export default TransactionPaymentPage;
