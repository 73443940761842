import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../Configs/firebase";

const getPropertyIndex = async () => {
  try {
    let arr = [];
    const q = query(collection(db, "property"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    return arr;
  } catch (error) {
    console.log(error);
  }
};

const getPropertyDetail = async (param) => {
  const docRef = doc(db, "property", param);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
  } else {
    console.log("No such document!");
  }
  return docSnap.data();
};

const getPropertyTransaction = async (param) => {
  let arr = [];
  const q = query(collection(db, "orders"), where("property", "==", param));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    arr.push(doc.data());
  });
  return arr;
};

export { getPropertyDetail, getPropertyTransaction, getPropertyIndex };
