import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { auth, db } from "../Configs/firebase";
import { Logo } from "../Components/AppComponents/LogoComponent";
import { AuthButtonGroup } from "../Components/AppComponents/AuthButtonApp";
import { collection, getDocs, query, where } from "firebase/firestore";
import { getCurrentUser } from "../Api/Admin/apiUsers";

function LoginPage() {
  var store = require("store");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  let navigate = useNavigate();

  const onHandleLogin = () => {
    try {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          const getUser = getCurrentUser(auth.currentUser.uid);
          return getUser;
        })
        .then((data) => {
          console.log(data);
          store.set("userData", data);
        })
        .then(() => navigate("/dashboard"))
        .catch((err) => alert("Wrong Password", err.message));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container
      maxW="full"
      h="100vh"
      py={{ base: "12", md: "20" }}
      px={{ base: "0", sm: "8" }}
      bgColor="#ffd600"
    >
      <Center>
        <Box
          px={{ base: "4", sm: "10" }}
          pt={{ base: "10", sm: "10" }}
          pb={{ base: "10", sm: "10" }}
          bg={useBreakpointValue({ base: "#ffffff", sm: "#ffffff" })}
          boxShadow={{ base: "none", sm: useColorModeValue("md", "md-dark") }}
          borderRadius={{ base: "lg", sm: "xl" }}
        >
          <Stack spacing="8">
            <Stack spacing="6" align="center">
              <Image src="/propertylogo.png" maxWidth="200px" />
              <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
                <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
                  Log in to your account
                </Heading>
              </Stack>
            </Stack>
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <Input
                    id="password"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
                {/* <PasswordField /> */}
              </Stack>
              <HStack justify="space-between">
                <Checkbox defaultIsChecked>Remember me</Checkbox>
                <Button variant="link" colorScheme="blue" size="sm">
                  Lupa Password?
                </Button>
              </HStack>
              <Stack spacing="6">
                <Button colorScheme="blue" onClick={() => onHandleLogin()}>
                  Sign in
                </Button>
              </Stack>
              {/* <Stack spacing="6">
              <Link to="/signup" onClick={() => console.log('masuk')}>
          <Button
            as="a"
            href="#"
            size="lg"
            colorScheme="green"
            px="8"
            fontWeight="bold"
            fontSize="md"
          >
            signup 
          </Button>
          </Link>
              </Stack> */}
            </Stack>
          </Stack>
        </Box>
      </Center>
    </Container>
  );
}

export default LoginPage;
