import React, { useState, useEffect } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Image,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useColorModeValue,
  Heading,
  Center,
} from "@chakra-ui/react";
import { collection, getDocs, query, where } from "firebase/firestore";
import CurrencyFormat from "react-currency-format";

import { auth, db } from "../../../Configs/firebase";
import { FiSearch, FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";
import colors from "../../../Configs/colors";

function AppTableTransaction() {
  var moment = require("moment");
  const [transaction, setTransaction] = useState([]);

  const user = auth.currentUser.uid;

  const getData = async () => {
    let arr = [];
    // const emailData = sessionStorage.getItem("email")
    const col = collection(db, "orders");
    const q = query(col, where("userID", "==", user));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    setTransaction(arr);
    console.log(arr);
  };

  useEffect(() => {
    getData();
    return () => {
      setTransaction([]);
    };
  }, []);

  const MemberTable = (props) => (
    <Table {...props}>
      <Thead backgroundColor={colors.theme}>
        <Tr>
          <Th>
            <HStack spacing="3">
              <HStack spacing="1">
                <Text>Transaction</Text>
              </HStack>
            </HStack>
          </Th>
          <Th>Status</Th>
          <Th>Property</Th>
          <Th>Lot</Th>
          <Th>Amount</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {transaction.map((member, index) => (
          <Tr key={index}>
            <Td>
              <HStack spacing="3">
                <Avatar
                  name={member.property}
                  src={member.image}
                  boxSize="10"
                />
                <Box>
                  <Text fontWeight="medium">{member.property}</Text>
                  <Text color="muted">{member.time}</Text>
                </Box>
              </HStack>
            </Td>

            <Td>
              <Badge
                size="sm"
                colorScheme={member.status === "PAID" ? "green" : "red"}
              >
                {member.status}
              </Badge>
            </Td>
            <Td>
              <Text color="muted">{member.type}</Text>
            </Td>
            <Td>
              <Text color="muted">{member.sold}</Text>
            </Td>
            <Td>
              <CurrencyFormat
                value={member.amount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"IDR "}
              />
            </Td>
            <Td>
              <Link to={`/transaction/${member.external_id}`}>
                <HStack spacing="1">
                  <IconButton
                    icon={<FiEye fontSize="1.25rem" />}
                    variant="ghost"
                    aria-label="Delete member"
                  />
                </HStack>
              </Link>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <Box bg="bg-surface">
      <Stack spacing="5">
        <Box
          px={{ base: "5", md: "8" }}
          backgroundColor={colors.theme}
          boxShadow="md"
          py={5}
          pl={3}
          borderRadius={5}
        >
          <Stack
            direction={{ base: "column", md: "row" }}
            justify="space-between"
          >
            <Stack spacing="1">
              <Heading
                size={useBreakpointValue({
                  base: "xs",
                  lg: "sm",
                })}
                fontWeight="Bold"
                fontSize="230%"
                letterSpacing={1}
                color={colors.dark}
              >
                Transaction
              </Heading>
              <Text color={colors.darklight} fontSize="80%">
                Your transaction history
              </Text>
            </Stack>
            <InputGroup maxW="xs">
              <InputLeftElement pointerEvents="none">
                <Icon as={FiSearch} color="muted" boxSize="5" />
              </InputLeftElement>
              <Input placeholder="Search" backgroundColor={colors.white} />
            </InputGroup>
          </Stack>
        </Box>

        <Box overflowX="auto" boxShadow="lg" rounded={10}>
          <MemberTable />
        </Box>

        <Box px={{ base: "4", md: "6" }} pb="5">
          <HStack spacing="3" justify="space-between">
            <Text color="muted" fontSize="sm"></Text>

            <ButtonGroup
              spacing="3"
              justifyContent="space-between"
              width={{ base: "full", md: "auto" }}
              variant="secondary"
            >
              <Button>Previous</Button>
              <Button>Next</Button>
            </ButtonGroup>
          </HStack>
        </Box>
      </Stack>
    </Box>
  );
}

export default AppTableTransaction;
