import {
  Box,
  Container,
  Heading,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import colors from "../../Configs/colors";
import AppCardAcademy from "../AppComponents/AcademyComponents/AppCardAcademy";

import AppComingSoon from "../AppComponents/AppComingSoon";

function AppBarAcademy() {
  let navigate = useNavigate();
  const [isDesktop, setDesktop] = useState(window.innerWidth > 680);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 680);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <Container maxW="container.xl" py="8" flex="1">
      <Stack
        spacing={{
          base: "8",
          lg: "6",
        }}
      >
        <HStack
          spacing="4"
          direction="row"
          justify="space-between"
          backgroundColor={colors.theme}
          boxShadow="md"
          py={5}
          pl={3}
          borderRadius={5}
        >
          <Stack spacing="1" ml={5}>
            <Heading
              size={useBreakpointValue({
                base: "xs",
                lg: "sm",
              })}
              fontWeight="Bold"
              fontSize="230%"
              letterSpacing={1}
              color={colors.dark}
            >
              Academy
            </Heading>
            <Text color={colors.darklight} fontSize="80%">
              Learn your property academy
            </Text>
          </Stack>
        </HStack>
        <Box pt={10}>
          <AppCardAcademy />
        </Box>
        {/* <AppComingSoon/> */}
      </Stack>
    </Container>
  );
}

export default AppBarAcademy;
