import React from 'react'
import { Box, SimpleGrid, Text, Image, Center, Input, VStack, HStack, Button, Flex } from '@chakra-ui/react'
import colors from '../../Configs/colors'
import coming from '../../assets/comingsoon.png'
import { ToggleButton } from './ToogleButton'
import { NavButton } from './AppButtonNavBar'
import { FiSend } from 'react-icons/fi'
import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'

export default function AppComingSoon() {
  return (
    <>
    <Center h='auto'>
    <SimpleGrid  
      columns={[1, null, 2]}
      spacing={200}
      backgroundRepeat='no-repeat'
      backgroundSize='cover'
      py={50}
      >
        <VStack spacing={8}>
          <Box>
            <Text 
            color={colors.danger} 
            fontWeight='bold'
            fontSize='23px'

            >11 Days : 6 hrs : 23 mins</Text>
          </Box>
          <Box>
          <Text fontWeight='bold' fontSize='45px' letterSpacing={3} >All Good Things</Text>
          <Text fontWeight='bold' fontSize='45px' letterSpacing={3} >Come to Those</Text>
          <Text fontWeight='bold' fontSize='45px' letterSpacing={3} >Who Wait..</Text>
          </Box>
          <Box w='80%'>
            <Text fontWeight='bold' fontSize='15px'>get notified when we launch</Text>
            <HStack>
            <Input placeholder='email' backgroundColor={colors.light} />
            <NavButton
                    icon={FiSend}
                    bgColor={colors.danger}
                    textColor="black"
                    iconColor="black"
                    // onClick={() => navigate("/admin/add")}
                  />
            </HStack>
          </Box>
         
        </VStack>
        <Box backgroundColor={colors.theme} borderRadius={50}>
          <Image
          boxSize='400px'
          objectFit='cover'
          src={coming}
          alt='Dan Abramov'
          />
        </Box>
    </SimpleGrid >
    </Center>
    <Flex flexDirection='column' alignItems='center'  backgroundColor={colors.light} py={5} > 
    <HStack spacing={10} >
      <Box label={'Twitter'} href={'#'}>
        <FaTwitter />
      </Box>
      <Box label={'Twitter'} href={'#'}>
        <FaInstagram />
      </Box>
      <Box label={'Twitter'} href={'#'}>
        <FaYoutube />
      </Box>
    </HStack>
    <Box mt={3}>
      <Text color='gray.500' fontSize='10px'>Copyright by Property.co.id | All Right Reservaved </Text>
    </Box>
    </Flex>
    </>

  )
}
