import { Center, SimpleGrid, VStack, Text } from "@chakra-ui/react";
import React from "react";
import { FaUserPlus, FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import colors from "../../../Configs/colors";
import { NavButton } from "../AppButtonNavBar";
import AdminAppTableUser from "./AdminAppTableUser";

const AdminAppAdd = () => {
  const navigate = useNavigate();

  return (
    <>
      <Center>
        <SimpleGrid columns={[1, null, 2]} spacing={5}>
          <NavButton
            label="Add User"
            icon={FaUserPlus}
            bgColor="#ffd600"
            textColor="black"
            iconColor="black"
            onClick={() => navigate("/admin/add/user")}
          />
          <NavButton
            label="Add Membership"
            icon={FaUsers}
            bgColor="#ffd600"
            textColor="black"
            iconColor="black"
            onClick={() => navigate("/admin/add/user")}
          />
        </SimpleGrid>
      </Center>
      <AdminAppTableUser />
    </>
  );
};

export default AdminAppAdd;
