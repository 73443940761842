import React, { useState, useEffect } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Heading,
  Center,
  SkeletonCircle,
} from "@chakra-ui/react";
import CurrencyFormat from "react-currency-format";

import { FiSearch, FiEye, FiRefreshCcw } from "react-icons/fi";
import { Link } from "react-router-dom";
import colors from "../../../Configs/colors";
import {
  collection,
  endBefore,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../../../Configs/firebase";

function AdminAppTableUser() {
  const [userData, setUserData] = useState([]);
  const [getQuery, setGetQuery] = useState([]);
  const [first, setFirst] = useState([]);
  const [checkFirst, setCheckFirst] = useState("");
  const [checkEmail, setCheckEmail] = useState("");

  const [searchEmail, setSearchEmail] = useState("");

  const getUserData = async (status, value) => {
    try {
      // get next data
      if (status === "next") {
        let arr = [];
        const q = query(
          collection(db, "users"),
          orderBy("name_user", "asc"),
          startAfter(value),
          limit(10)
        );
        const querySnapshot = await getDocs(q);

        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        const firstVisible = querySnapshot.docs[querySnapshot.docs.length - 10];

        setFirst(firstVisible);
        console.log("next first", first);

        setGetQuery(lastVisible);
        console.log("next last", getQuery);

        querySnapshot.forEach((doc) => {
          arr.push(doc.data());
        });

        setUserData(arr);
        setCheckEmail(arr[0].email_user);
        return arr;
      }

      // get previous data
      if (status === "prev") {
        let arr = [];

        const q = query(
          collection(db, "users"),
          orderBy("name_user", "asc"),
          endBefore(value),
          limitToLast(10)
        );
        const querySnapshot = await getDocs(q);

        const lastVisible2 = querySnapshot.docs[querySnapshot.docs.length - 1];
        const firstVisible2 =
          querySnapshot.docs[querySnapshot.docs.length - 10];

        setFirst(firstVisible2);
        console.log("prev first", firstVisible2);

        setGetQuery(lastVisible2);
        console.log("prev last", lastVisible2);

        querySnapshot.forEach((doc) => {
          arr.push(doc.data());
        });

        setUserData(arr);
        setCheckEmail(arr[0].email_user);

        return arr;
      }

      // get first data
      let arr = [];
      const q = query(
        collection(db, "users"),
        orderBy("name_user", "asc"),
        limit(10)
      );
      const querySnapshot = await getDocs(q);

      const lastVisible3 = querySnapshot.docs[querySnapshot.docs.length - 1];
      const firstVisible3 = querySnapshot.docs[querySnapshot.docs.length - 10];
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });

      setFirst(firstVisible3);
      setGetQuery(lastVisible3);
      setCheckFirst(arr[0].email_user);

      setUserData(arr);
      setCheckEmail(arr[0].email_user);

      return arr;
    } catch (error) {
      console.log(error);
    }
  };

  const getUSerDataByEmail = async (email) => {
    try {
      let arr = [];
      const q = query(
        collection(db, "users"),
        where("email_user", ">=", email),
        where("email_user", "<=", email + "\uf8ff")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });
      setUserData(arr);
      return arr;
    } catch (error) {
      console.log(error);
    }
  };

  console.log("first", first);
  console.log("last", getQuery);
  console.log("check first userData[0]", checkFirst);
  console.log("checkEmail", checkEmail);

  useEffect(() => {
    getUserData();
    return () => {
      setUserData([]);
      setGetQuery([]);
      setFirst([]);
      setCheckFirst([]);
      setCheckEmail("");
      setSearchEmail("");
    };
  }, []);

  const MemberTable = (props) => (
    <Table {...props} boxShadow="lg">
      <Thead backgroundColor={colors.theme}>
        <Tr>
          <Th>
            <HStack>
              <Text>Nama</Text>
            </HStack>
          </Th>
          <Th>User</Th>
          <Th>No. Hp</Th>
          <Th>No. Rekening</Th>
          <Th>Bukti Transfer</Th>
          <Th>Payment</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {Array.isArray(userData) &&
          userData.map((data, index) => (
            <Tr key={index}>
              <Td>
                <HStack>
                  {data.image ? (
                    <Avatar src={data.image} boxSize="10" />
                  ) : (
                    <SkeletonCircle size="10" />
                  )}
                  <Box>
                    <Text fontSize={13}>{data.user_name}</Text>
                    <Text color="muted" fontSize={12}>
                      {data.email_user}
                    </Text>
                  </Box>
                </HStack>
              </Td>
              <Td>
                <Badge
                  size="sm"
                  colorScheme={data.user_role === "admin" ? "green" : "yellow"}
                  fontSize={13}
                >
                  {data.user_role}
                </Badge>
              </Td>
              <Td>
                <Text color="muted" fontSize={13}>
                  {data.user_phone}
                </Text>
              </Td>
              <Td>
                {data.bank_name ? (
                  <Text color="muted" fontSize={13}>
                    {data.bank_name} / {data.account_number}
                  </Text>
                ) : (
                  <Text fontSize={13}>No Input</Text>
                )}
              </Td>
              <Td>
                <a href={data.prof_payment} target="blank">
                  {data.prof_payment ? (
                    <Avatar src={data.prof_payment} boxSize="10" />
                  ) : (
                    <SkeletonCircle size="10" />
                  )}
                </a>
              </Td>
              <Td>
                {data.payment_user ? (
                  <CurrencyFormat
                    value={data.payment_user}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"IDR "}
                  />
                ) : (
                  <Text>Rp. 0</Text>
                )}
              </Td>
              <Td>
                <HStack spacing="1">
                  <Link to={`/admin/transaction/${data.external_id}`}>
                    <IconButton
                      icon={<FiEye fontSize="1.25rem" />}
                      variant="ghost"
                      aria-label="Delete member"
                    />
                  </Link>
                </HStack>
              </Td>
            </Tr>
          ))}
      </Tbody>
    </Table>
  );

  return (
    <Box bg="bg-surface">
      <Stack spacing="5">
        <Box px={{ base: "4", md: "6" }}>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify="space-between"
          >
            <Box>
              <Heading fontWeight="medium" fontSize="large">
                Users
              </Heading>
              <Text color="muted">All Users List</Text>
            </Box>
            <HStack>
              <InputGroup maxW="xs">
                <Input
                  placeholder="Search by Email"
                  onChange={(e) => setSearchEmail(e.target.value)}
                />
              </InputGroup>
              <Button
                bgColor="#ffd600"
                onClick={() => getUSerDataByEmail(searchEmail)}
              >
                <FiSearch />
              </Button>
              <Button bgColor="green.300" onClick={getUserData}>
                <FiRefreshCcw />
              </Button>
            </HStack>
          </Stack>
        </Box>
        <Box overflowX="auto" p={3}>
          <MemberTable />
        </Box>
        <Box px={{ base: "4", md: "6" }} pb="5">
          <HStack spacing="3" justify="flex-end">
            <ButtonGroup
              spacing="3"
              justifyContent="space-between"
              width={{ base: "full", md: "auto" }}
              variant="secondary"
            >
              {checkFirst === checkEmail ? (
                <></>
              ) : (
                <Button onClick={() => getUserData("prev", first)}>
                  Previous
                </Button>
              )}

              {userData.length !== 10 ? (
                <></>
              ) : (
                <Button onClick={() => getUserData("next", getQuery)}>
                  Next
                </Button>
              )}
            </ButtonGroup>
          </HStack>
        </Box>
      </Stack>
    </Box>
  );
}

export default AdminAppTableUser;
