import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
  useColorModeValue,
  useColorModeValue as mode,
  Circle,
  AspectRatio,
  Image,
  Skeleton,
  Progress,
  Badge,
  VStack,
  Input,
  useDisclosure,
  Avatar,
  ButtonGroup,
  Icon,
  InputGroup,
  InputLeftElement,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { useKeenSlider } from "keen-slider/react";
import { FiSearch, FiEye, FiX, FiEdit, FiEdit2 } from "react-icons/fi";
// using version 6.6.10
import { auth, db } from "../../../Configs/firebase";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  doc,
  getDoc,
  collection,
  query,
  getDocs,
  where,
  updateDoc,
} from "firebase/firestore";
import CurrencyFormat from "react-currency-format";
import { BsCheckSquareFill } from "react-icons/bs";

import colors from "../../../Configs/colors";
import { NavButton } from "../AppButtonNavBar";
import {
  getPropertyDetail,
  getPropertyTransaction,
} from "../../../Api/Admin/apiProperty";

const AdminPropertyDetail = () => {
  let navigate = useNavigate();
  let param = useParams();
  const { id } = useParams();

  const [data, setData] = useState("");
  const [transaction, setTransaction] = useState([]);

  const getPropertyData = async () => {
    const response = await getPropertyDetail(param.id);
    setData(response);
    console.log(response, "getPropertyData");
  };

  const getTransactionData = async () => {
    const transactionData = await getPropertyTransaction(id);
    console.log(transactionData, "getTransactionData");
    setTransaction(transactionData);
  };

  const onHandleApproved = async () => {
    try {
      const docSnap = await updateDoc(
        doc(db, "property", param.id),
        {
          approved: true,
        },
        { merge: true }
      );
      console.log(docSnap, "onHandleApproved");
      navigate("/admin/property");
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleEdit = async () => {
    navigate(`/admin/property/edit/${param.id}`);
  };

  React.useEffect(() => {
    getPropertyData();
    getTransactionData();
  }, []);

  const images = [
    {
      id: "01",
      src: "/rumah.jpeg",
      alt: "Awesome watch",
    },
    {
      id: "02",
      src: "/kosan.jpeg",
      alt: "Awesome watch",
    },
    {
      id: "03",
      src: "https://images.unsplash.com/photo-1568010434570-74e9ba7126bc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80",
      alt: "Awesome watch",
    },
    {
      id: "04",
      src: "https://images.unsplash.com/photo-1569411032431-07598b0012c2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
      alt: "Awesome watch",
    },
    {
      id: "05",
      src: "https://images.unsplash.com/photo-1565440962783-f87efdea99fd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=936&q=80",
      alt: "Awesome watch",
    },
    {
      id: "06",
      src: "https://images.unsplash.com/photo-1548169874-53e85f753f1e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1420&q=80",
      alt: "Awesome watch",
    },
  ];

  const Gallery = (props) => {
    const Carousel = React.forwardRef(function Carousel(props, ref) {
      return (
        <Flex
          ref={ref}
          className="chakra-carousel"
          overflow="hidden"
          position="relative"
          userSelect="none"
          {...props}
        />
      );
    });
    const CarouselSlide = (props) => (
      <Box
        className="chakra-carousel__slide"
        position="relative"
        overflow="hidden"
        width="100%"
        minH="100%"
        {...props}
      />
    );
    const CarouselIconButton = (props) => (
      <IconButton
        variant="unstyled"
        boxSize="auto"
        minW="auto"
        display="inline-flex"
        fontSize="2xl"
        color={useColorModeValue("gray.600", "gray.400")}
        _hover={{
          color: useColorModeValue("blue.500", "blue.300"),
          _disabled: {
            color: useColorModeValue("gray.600", "gray.400"),
          },
        }}
        _active={{
          color: useColorModeValue("blue.600", "blue.400"),
        }}
        _focus={{
          boxShadow: "none",
        }}
        _focusVisible={{
          boxShadow: "outline",
        }}
        {...props}
      />
    );
    const useCarousel = (options) => {
      const defaultOptions = {
        selector: ".chakra-carousel__slide",
      };
      return useKeenSlider({ ...defaultOptions, ...options });
    };
    const { images, aspectRatio = 4 / 3, rootProps } = props;
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [ref, slider] = useCarousel({
      slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
    });
    const hasPrevious = currentSlide !== 0;
    const hasNext = currentSlide < images.length - 1;
    return (
      <Stack spacing="4" {...rootProps}>
        <Box
          position="relative"
          sx={{
            _hover: {
              "> button": {
                display: "inline-flex",
              },
            },
          }}
        >
          <Carousel ref={ref}>
            {images.map((image, i) => (
              <CarouselSlide key={i}>
                <AspectRatio
                  ratio={aspectRatio}
                  transition="all 200ms"
                  opacity={currentSlide === i ? 1 : 0.4}
                  _hover={{
                    opacity: 1,
                  }}
                >
                  <Image
                    src={data.imageUrl}
                    objectFit="cover"
                    alt={image.alt}
                    fallback={<Skeleton />}
                  />
                </AspectRatio>
              </CarouselSlide>
            ))}
          </Carousel>
          {hasPrevious && (
            <CarouselIconButton
              pos="absolute"
              left="3"
              top="50%"
              transform="translateY(-50%)"
              onClick={() => slider.current?.prev()}
              icon={<IoChevronBackOutline />}
              aria-label="Previous Slide"
            />
          )}

          {hasNext && (
            <CarouselIconButton
              pos="absolute"
              right="3"
              top="50%"
              transform="translateY(-50%)"
              onClick={() => slider.current?.next()}
              icon={<IoChevronForwardOutline />}
              aria-label="Next Slide"
            />
          )}
          <HStack
            position="absolute"
            width="full"
            justify="center"
            bottom="0"
            py="4"
          >
            {images.map((_, index) => (
              <Circle
                key={index}
                size="2"
                bg={currentSlide == index ? "white" : "whiteAlpha.400"}
              />
            ))}
          </HStack>
        </Box>
      </Stack>
    );
  };

  const formatPrice = (value, opts = {}) => {
    const { locale = "en-US", currency = "USD" } = opts;
    const formatter = new Intl.NumberFormat(locale, {
      currency,
      style: "currency",
      maximumFractionDigits: 2,
    });
    return formatter.format(value);
  };

  const PriceTag = (props) => {
    const {
      price,
      currency,
      salePrice,
      rootProps,
      priceProps,
      salePriceProps,
    } = props;
    return (
      <HStack spacing="1" {...rootProps}>
        <Price isOnSale={!!salePrice} textProps={priceProps}>
          {formatPrice(price, {
            currency,
          })}
        </Price>
        {salePrice && (
          <SalePrice {...salePriceProps}>
            {formatPrice(salePrice, {
              currency,
            })}
          </SalePrice>
        )}
      </HStack>
    );
  };

  const Price = (props) => {
    const { isOnSale, children, textProps } = props;
    const defaultColor = mode("gray.700", "gray.400");
    const onSaleColor = mode("gray.400", "gray.700");
    const color = isOnSale ? onSaleColor : defaultColor;
    return (
      <Text
        as="span"
        fontWeight="medium"
        color={color}
        textDecoration={isOnSale ? "line-through" : "none"}
        {...textProps}
      >
        {children}
      </Text>
    );
  };

  const SalePrice = (props) => (
    <Text
      as="span"
      fontWeight="semibold"
      color={mode("gray.800", "gray.100")}
      {...props}
    />
  );

  function AppCardPropertyTable() {
    const MemberTable = (props) => (
      <Table {...props} boxShadow="lg" m={5}>
        <Thead backgroundColor={colors.theme}>
          <Tr>
            <Th>
              <HStack spacing="3">
                <HStack spacing="1">
                  <Text>Transaction</Text>
                </HStack>
              </HStack>
            </Th>
            <Th>User</Th>
            <Th>Status</Th>
            <Th>Property</Th>
            <Th>Lot</Th>
            <Th>Amount</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {transaction.map((member, index) => (
            <Tr key={index}>
              <Td>
                <HStack spacing="3">
                  <Avatar
                    name={member.property}
                    src={member.image}
                    boxSize="10"
                  />
                  <Box>
                    <Text fontWeight="medium">{member.property}</Text>
                    <Text color="muted">{member.time}</Text>
                  </Box>
                </HStack>
              </Td>
              <Td>
                <Text color="muted">{member.email}</Text>
              </Td>
              <Td>
                <Badge
                  size="sm"
                  colorScheme={member.status === "DIVIDEN" ? "green" : "red"}
                >
                  {member.status}
                </Badge>
              </Td>
              <Td>
                <Text color="muted">{member.type}</Text>
              </Td>
              <Td>
                <Text color="muted">{member.sold}</Text>
              </Td>
              <Td>
                <CurrencyFormat
                  value={member.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"IDR "}
                />
              </Td>
              <Td></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );

    return (
      <Box bg="bg-surface">
        <Stack spacing="5">
          <Box px={{ base: "4", md: "6" }} pt="5">
            <Stack
              direction={{ base: "column", md: "row" }}
              justify="space-between"
            >
              <InputGroup maxW="xs">
                <InputLeftElement pointerEvents="none">
                  <Icon as={FiSearch} color="muted" boxSize="5" />
                </InputLeftElement>
                <Input placeholder="Search" />
              </InputGroup>
            </Stack>
          </Box>
          <Box overflowX="auto">
            <MemberTable />
          </Box>
          <Box px={{ base: "4", md: "6" }} pb="5">
            <HStack spacing="3" justify="space-between">
              <Text color="muted" fontSize="sm">
                Showing 1 to 5 of 42 results
              </Text>

              <ButtonGroup
                spacing="3"
                justifyContent="space-between"
                width={{ base: "full", md: "auto" }}
                variant="secondary"
              >
                <Button>Previous</Button>
                <Button>Next</Button>
              </ButtonGroup>
            </HStack>
          </Box>
        </Stack>
      </Box>
    );
  }

  return (
    <Box
      maxW="7xl"
      mx="auto"
      px={{
        base: "0",
        md: "2",
        lg: "6",
      }}
    >
      {!data.approved ? (
        <Box p="5" bgColor="#ffd600" mb="5" rounded="10px">
          <HStack spacing="5" justifyContent="space-between">
            <Box>
              <Heading as="h1" size="md" color="black">
                Approval Status
              </Heading>
            </Box>
            <HStack spacing="3">
              <Button onClick={onHandleEdit}>
                <FiEdit color="black" />
              </Button>
              <Button onClick={onHandleApproved}>
                <BsCheckSquareFill
                  color="black
              "
                />
              </Button>
            </HStack>
          </HStack>
        </Box>
      ) : (
        <></>
      )}
      <Stack
        direction={{
          base: "column-reverse",
          lg: "row",
        }}
        spacing={{
          base: "6",
          lg: "12",
          xl: "16",
        }}
      >
        <Stack
          spacing={{
            base: "6",
            lg: "3",
          }}
          maxW={{
            lg: "sm",
          }}
          justify="center"
        >
          <Stack
            spacing={{
              base: "3",
              md: "4",
            }}
          >
            <Stack spacing="3">
              <Badge
                borderRadius="full"
                px="2"
                colorScheme="yellow"
                maxW="75px"
              >
                <Center>RUMAH</Center>
              </Badge>
              <Heading size="lg" fontWeight="medium">
                {data.name}
              </Heading>
            </Stack>
            <PriceTag
              price={data.lot * data.lotPrice}
              currency="IDR"
              rootProps={{
                fontSize: "xl",
              }}
            />
            <HStack>
              <Text color={useColorModeValue("gray.600", "gray.400")}>
                {data.description}
              </Text>
            </HStack>
            <Box>
              <Progress
                size="sm"
                value={data.sold}
                max={data.lot}
                colorScheme="yellow"
                hasStripe={true}
                isAnimated={true}
              />
            </Box>

            <Box
              pr={{
                base: "4",
                md: "0",
                lg: "12",
              }}
              maxH="200"
              maxW="full"
            >
              <VStack spacing="20px">
                <HStack justifyContent="space-around">
                  <Flex
                    flexDirection="column"
                    maxHeight="auto"
                    w="150px"
                    h="50px"
                    backgroundColor={colors.theme}
                    align="center"
                    justifyContent="center"
                    borderRadius={10}
                    boxShadow="md"
                  >
                    <Heading
                      color="gray.500"
                      fontWeight="semibold"
                      letterSpacing="wide"
                      fontSize="xs"
                      textTransform="uppercase"
                    >
                      Harga per Lot
                    </Heading>
                    <PriceTag
                      price={data.lotPrice}
                      currency="IDR"
                      rootProps={{
                        fontWeight: "semibold",
                        fontSize: "sm",
                        textTransform: "uppercase",
                      }}
                    />
                  </Flex>
                  <Flex
                    flexDirection="column"
                    maxHeight="auto"
                    w="150px"
                    h="50px"
                    backgroundColor={colors.theme}
                    align="center"
                    justifyContent="center"
                    borderRadius={10}
                    boxShadow="md"
                  >
                    <Heading
                      color="gray.500"
                      fontWeight="semibold"
                      letterSpacing="wide"
                      fontSize="xs"
                      textTransform="uppercase"
                    >
                      Jumlah Lot
                    </Heading>
                    <Text
                      fontWeight="semibold"
                      fontSize="sm"
                      textTransform="uppercase"
                    >
                      {data.lot}
                    </Text>
                  </Flex>
                </HStack>

                <HStack justifyContent="space-around">
                  <Flex
                    flexDirection="column"
                    maxHeight="auto"
                    w="150px"
                    h="50px"
                    backgroundColor={colors.theme}
                    align="center"
                    justifyContent="center"
                    borderRadius={10}
                    boxShadow="md"
                  >
                    <Heading
                      color="gray.500"
                      fontWeight="semibold"
                      letterSpacing="wide"
                      fontSize="xs"
                      textTransform="uppercase"
                    >
                      Periode Dividen
                    </Heading>
                    <Text
                      fontWeight="semibold"
                      fontSize="sm"
                      textTransform="uppercase"
                    >
                      {data.periode + " Bulan"}
                    </Text>
                  </Flex>
                  <Flex
                    flexDirection="column"
                    maxHeight="auto"
                    w="150px"
                    h="50px"
                    backgroundColor={colors.theme}
                    align="center"
                    justifyContent="center"
                    borderRadius={10}
                    boxShadow="md"
                  >
                    <Heading
                      color="gray.500"
                      fontWeight="semibold"
                      letterSpacing="wide"
                      fontSize="xs"
                      textTransform="uppercase"
                    >
                      Estimasi Dividen
                    </Heading>
                    <Text
                      fontWeight="semibold"
                      fontSize="sm"
                      textTransform="uppercase"
                    >
                      {data.dividen + " % / Tahun"}
                    </Text>
                  </Flex>
                </HStack>
              </VStack>
            </Box>
          </Stack>

          <HStack
            spacing={{
              base: "4",
              md: "8",
            }}
            align="flex-end"
            justify="space-evenly"
          >
            <Box flex="1" mb={5}></Box>
          </HStack>
        </Stack>
        <Gallery
          rootProps={{
            overflow: "hidden",
            flex: "1",
          }}
          images={images.slice(0, 5)}
        />
      </Stack>
      <Center mt={8}>
        <Text fontSize={20} fontWeight="bold">
          Transaction History
        </Text>
      </Center>
      <Box>
        <AppCardPropertyTable />
      </Box>
    </Box>
  );
};

export default AdminPropertyDetail;
