import { Box, Container, Heading, HStack, Stack, Text } from "@chakra-ui/react";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../Configs/firebase";
import AppCardPropertyDetail from "../AppComponents/PropertyComponents/AppCardPropertyDetail";
import AppTableTransactionDetail from "../AppComponents/TransactionComponents/AppTableTransactionDetail";

function AppBarTransactionDetail() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 680);
  const [transaction, setTransaction] = useState([])
  const { id } = useParams(); 

  // const user = auth.currentUser.email
  // console.log('firebase auth', user)

  const getData = async () => {
    let arr = [];
    // const emailData = sessionStorage.getItem("email")
    const col = collection(db, "orders")
    const q = query(col, where("external_id", "==", id));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    setTransaction(arr);
  };

  useEffect(() => {
    getData();
    window.addEventListener("resize", updateMedia);
    return () => {
      setTransaction([]);
      window.removeEventListener("resize", updateMedia);
    };
  }, []);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 680);
  };

  return (
    <Container maxW="container.xl" pt={5} flex="1">
      <Stack
        spacing={{
          base: "3",
          lg: "6",
        }}
      >
        <HStack spacing="4" direction="row" justify="space-between"></HStack>
        {transaction.map((item) => (
          <AppTableTransactionDetail 
          type={item.type}
          image={item.image}
          property={item.property}
          status={item.status}
          externalid={item.externalid}
          sold={item.sold}
          amount={item.amount}
          email={item.email}
          createdat={item.createdat}
          description={item.description}
          />
        ))}
      </Stack>
    </Container>
  );
}

export default AppBarTransactionDetail;
