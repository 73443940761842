import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../Configs/firebase";

//get All Course Data in a Courses Collection
const getCoursesIndex = async () => {
  let arr = [];
  const q = query(collection(db, "courses"));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    arr.push(doc.data());
  });
  return arr;
};

//get All Lesson Data in a Course Doc
const getLessonsIndex = async (courseId) => {
  let arr = [];
  const q = query(collection(db, `courses/${courseId}/lessons`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    arr.push(doc.data());
  });
  return arr;
};

//get Specific Course Data in a Courses Collection
const getCourseDetail = async (courseId) => {
  const docRef = doc(db, "courses", courseId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
  } else {
    console.log("No such document!");
  }
  return docSnap.data();
};

//get Specific Lesson Data in a Course Doc
const getLessonDetail = async (courseId, lessonId) => {
  const docRef = doc(db, `courses/${courseId}/lessons`, lessonId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
  } else {
    console.log("No such document!");
  }
  return docSnap.data();
};

export { getCoursesIndex, getCourseDetail, getLessonsIndex, getLessonDetail };
