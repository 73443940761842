import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderIndex, order_id } from "../../Api/Admin/apiOrders";
import {
  getPropertyDetail,
  getPropertyIndex,
} from "../../Api/Admin/apiProperty";
import { db } from "../../Configs/firebase";

function AdminTransactionAddPage() {
  let navigate = useNavigate();
  let param = useParams();

  const [name, setName] = useState("");
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);

  const [property, setProperty] = useState("");
  const [user, setUser] = useState("");
  const [lotQty, setLotQty] = useState(0);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 460);

  const getPropertyList = async () => {
    const q = await getPropertyIndex();
    console.log(q);
    setData(q);
  };

  const getUserList = async () => {
    try {
      let arr = [];
      const x = query(collection(db, "users"));
      const querySnapshot = await getDocs(x);
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });

      setUserData(arr);
      return arr;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    try {
      const totalOrder = await getOrderIndex();
      let orderCount = totalOrder.length;

      let invData = {
        user_id: userData[user].email_user,
        orderId: "PCI00" + ++orderCount,
        invoice_id: "INV00" + orderCount,
      };

      const external_id =
        invData.orderId +
        "-" +
        invData.invoice_id +
        "-" +
        invData.user_id +
        "-" +
        moment().format("YYYYMMDDhhmm") +
        "-" +
        order_id +
        "-" +
        data[property].property_id +
        "-" +
        lotQty;

      const totalPrice = data[property].lotPrice * lotQty;
      const amount = totalPrice;

      const docRef = await setDoc(doc(db, "orders", order_id), {
        property: data[property].name,
        email: userData[user].email_user,
        sold: lotQty,
        type: data[property].type,
        amount: totalPrice,
        created_at: serverTimestamp(),
        paid_at: "",
        status: "UNPAID",
        payment_channel: "Bank Transfer",
        external_id: external_id,
        image: data[property].imageUrl,
        userID: userData[user].uid,
        userEmail: userData[user].email_user,
        order_id: order_id,
      });

      console.log("Document written with ID", order_id);
      navigate(`/admin/transaction`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPropertyList();
    getUserList();
    return () => {};
  }, []);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 460);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  console.log(property);
  console.log(userData);

  return (
    <Flex bg="bg-surface" flexDirection={isDesktop ? "row" : "column-reverse"}>
      <Stack spacing="5">
        <Box px={{ base: "4", md: "4" }} pt="2">
          <Stack
            direction={{ base: "column", md: "row" }}
            justify="space-between"
          >
            <Box>
              <Heading fontWeight="medium" fontSize="large">
                Add New Transaction
              </Heading>
              <Text color="muted">Manual Creation</Text>
            </Box>
          </Stack>
        </Box>
        <Box
          px={{ base: "4", md: "4" }}
          pt="2"
          display="flex"
          justifyContent="center"
        >
          <SimpleGrid columns={1} spacing={10} width={300}>
            <FormControl isRequired>
              <FormLabel htmlFor="name">Property</FormLabel>
              <Select
                placeholder="Select property"
                onChange={(e) => setProperty(e.target.value)}
              >
                {data.map((item, i) => (
                  <option key={i} value={i}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="name">User</FormLabel>
              <Select
                placeholder="Select User"
                onChange={(e) => setUser(e.target.value)}
              >
                {userData.map((item, i) => (
                  <option key={i} value={i}>
                    {item.email_user}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="name">Lot</FormLabel>
              <Input
                type="number"
                onChange={(e) => setLotQty(e.target.value)}
              />
              {property ? (
                <Text>Total: IDR {lotQty * data[property].lotPrice}</Text>
              ) : (
                <></>
              )}
            </FormControl>
            <Button colorScheme="green" onClick={handleSubmit}>
              Create Transaction
            </Button>
          </SimpleGrid>
        </Box>
      </Stack>
      <Stack>
        {property ? (
          <Box p={10}>
            <Flex
              flexDirection={isDesktop ? "row" : "column"}
              alignItems="center"
            >
              <Box mb={5}>
                <Image
                  src={property ? data[property].imageUrl : <></>}
                  maxWidth="300px"
                  rounded={5}
                  shadow="base"
                />
              </Box>
              <Heading fontWeight="medium" fontSize="large" textAlign="center">
                Property: {data[property].name}
              </Heading>
              <Box pt="2" maxWidth="300px">
                <Text color="muted">Harga: IDR {data[property].price}</Text>
                <Text color="muted">
                  Lot Tersisa: IDR {data[property].lot - data[property].sold}
                </Text>
                <br />
                {user ? (
                  <Box maxWidth="300px">
                    <Heading fontWeight="medium" fontSize="large">
                      Nama: {userData[user].name_user}
                    </Heading>
                    <Text>Email: {userData[user].email_user}</Text>
                    <Text>Phone: {userData[user].phone_number}</Text>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Flex>
          </Box>
        ) : (
          <></>
        )}
      </Stack>
    </Flex>
  );
}

export default AdminTransactionAddPage;
