import { Box, Container, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import AppTableTransaction from "../AppComponents/TransactionComponents/AppTableTransaction";

function AppBarTransaction() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 680);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 680);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <Container maxW="container.xl" py="8" flex="1">
      <Stack
        spacing={{
          base: "8",
          lg: "6",
        }}
      >
        <Box>
          <AppTableTransaction />
        </Box>
      </Stack>
    </Container>
  );
}

export default AppBarTransaction;
