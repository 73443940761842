import {
  Box,
  Container,
  Flex,
  Spacer,
  useBreakpointValue,
} from "@chakra-ui/react";
import * as React from "react";
import { Navbar } from "../AppBars/AppBarNavigation";
import { Sidebar } from "../AppBars/AppBarSide";

function MainLayout() {
  const [isDesktop, setDesktop] = React.useState(window.innerWidth > 460);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 460);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const AppLayout = (Content) => {
    return (
      <Flex height="100vh">
        {isDesktop ? (
          <Box height="full" overflowY="auto" justifyContent="flex-end">
            <Sidebar />
          </Box>
        ) : null}
        <Box
          direction={{
            base: "row",
            lg: "column",
          }}
          bg="bg-canvas"
          flex="1"
          overflowY="auto"
        >
          {isDesktop ? (
            <Content />
          ) : (
            <>
              <Navbar />
              <Container marginTop="10">
                <Content />
              </Container>
            </>
          )}
        </Box>
      </Flex>
    );
  };

  return { AppLayout };
}

export default MainLayout;
