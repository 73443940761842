import React from "react";
import MainLayout from "../Components/Layouts/MainLayout";
import AppBarSettings from "../Components/AppBars/AppBarSettings";

function SettingsPage() {
  const { AppLayout } = MainLayout();

  return <>{AppLayout(AppBarSettings)}</>;
}

export default SettingsPage;
