import React from "react";
import AppBarAdmin from "../../Components/AppBars/AppBarAdmin";
import MainLayout from "../../Components/Layouts/MainLayout";

function AdminPage() {
  const { AppLayout } = MainLayout();

  return <>{AppLayout(AppBarAdmin)}</>;
}

export default AdminPage;
