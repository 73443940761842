import {
  Flex,
  Stack,
  Image,
  Select,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Text,
  ModalFooter,
  Box,
  Avatar,
  SimpleGrid,
  HStack,
  SkeletonCircle,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FiLogOut } from "react-icons/fi";
import { IoBusiness } from "react-icons/io5";
import {
  MdPayments,
  MdSettings,
  MdDashboard,
  MdOutlineBrightness4,
  MdOutlineRealEstateAgent,
  MdAdminPanelSettings,
} from "react-icons/md";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import { signOut } from "firebase/auth";
import { auth } from "../../Configs/firebase";
import { NavButton } from "../AppComponents/AppButtonNavBar";
import { UserProfile } from "../AppComponents/UserProfile";
import colors from "../../Configs/colors";

export const Sidebar = () => {
  var store = require("store");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useState([]);

  let navigate = useNavigate();
  const userData = store.get("userData");
  const userRole = userData.user_role;
  console.log(userRole);

  const getData = () => {
    const storedArray = store.get("userData");
    const dataUser = storedArray;
    setUser(dataUser, "ini data user");
  };

  useEffect(() => {
    getData();
  }, []);

  const AdminButton = () => {
    if (userRole === "super_admin") {
      return (
        <NavButton
          label="Administrator"
          icon={MdAdminPanelSettings}
          bgColor="black"
          textColor="white"
          iconColor="white"
          onClick={() => navigate("/admin/add")}
        />
      );
    }
    if (userRole === "admin") {
      return (
        <NavButton
          label="Administrator"
          icon={MdAdminPanelSettings}
          bgColor="black"
          textColor="white"
          iconColor="white"
          onClick={() => navigate("/admin/add")}
        />
      );
    }
  };

  const handleLogOut = () => {
    signOut(auth)
      .then(() => {
        store.clearAll();
        navigate("/", { replace: true });
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <Flex
      flex="1"
      bg={colors.light}
      color="on-accent"
      overflowY="auto"
      height="full"
      justifyContent="flex-end"
      maxW={{
        base: "full",
        sm: "fit-content",
      }}
      py={{
        base: "6",
        sm: "8",
      }}
      px={{
        base: "5",
        sm: "5",
      }}
      shadow="lg"
    >
      <Stack justify="space-between" spacing="1">
        <Stack
          spacing={{
            sm: "6",
          }}
          shouldWrapChildren
        >
          <Image src="/propertylogo.png" maxWidth="200px" />
          <Stack spacing="1.5">
            <NavButton
              label="Dashboard"
              icon={MdDashboard}
              aria-current="page"
              onClick={() => navigate("/dashboard")}
            />
            <NavButton
              label="Property"
              icon={IoBusiness}
              onClick={() => navigate("/property")}
            />
            <NavButton
              label="Transaction"
              icon={MdPayments}
              onClick={() => navigate("/transaction")}
            />
            <NavButton
              label="Academy"
              icon={HiOutlineAcademicCap}
              bgColor={colors.theme}
              textColor="black"
              iconColor="black"
              onClick={() => navigate("/academy")}
            />
            <NavButton
              label="KPR Syariah"
              icon={MdOutlineBrightness4}
              bgColor="green.500"
              textColor="white"
              iconColor="white"
              onClick={() => navigate("/kprsyariah")}
            />
            <AdminButton />
          </Stack>
        </Stack>
        <Stack
          spacing={{
            base: "5",
            sm: "6",
          }}
        >
          <Stack spacing="1">
            <Button onClick={onOpen} py={7} boxShadow="dark-lg">
              <UserProfile
                image="https://tinyurl.com/yhkm2ek8"
                name={user.name_user}
                email={user.email_user}
              />
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent position="absolute" bottom={1} left={250} w="25">
                <ModalHeader backgroundColor={colors.theme}>
                  Profile
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Box></Box>
                  <Box>
                    <Flex alignItems="center" justifyContent="center">
                      {user.image_user ? (
                        <Avatar
                          size="100"
                          name="Christian Nwamba"
                          src={user.image_user}
                        />
                      ) : (
                        <SkeletonCircle size="100" />
                      )}
                      <Box ml="3">
                        <Text fontSize={20} fontWeight="bold" mt={1}>
                          {user.name_user}
                        </Text>
                        <Text fontSize={15} mt={1}>
                          {user.email_user}
                        </Text>
                        <HStack>
                          <Box
                            backgroundColor={colors.theme}
                            w="auto"
                            p={1}
                            borderRadius={10}
                          >
                            <Text
                              fontSize={15}
                              color={colors.light}
                              fontWeight="bold"
                              align="center"
                            >
                              {user.user_role}
                            </Text>
                          </Box>
                          <NavButton
                            label="Settings"
                            icon={MdSettings}
                            onClick={() => navigate("/settings")}
                          />
                        </HStack>
                      </Box>
                    </Flex>
                  </Box>
                </ModalBody>

                <ModalFooter color="red">
                  <NavButton
                    onClick={() => handleLogOut()}
                    label="Logout"
                    icon={FiLogOut}
                  />
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
};
