import React from "react";
import AppBarAttention from "../Components/AppBars/AppBarAttention";
import MainLayout from "../Components/Layouts/MainLayout";

function AttentionPage() {
  const { AppLayout } = MainLayout();

  return <>{AppLayout(AppBarAttention)}</>;
}

export default AttentionPage;
