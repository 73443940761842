import React from "react";
import AppBarAcademyLesson from "../Components/AppBars/AppBarAcademyLesson";
import MainLayout from "../Components/Layouts/MainLayout";

function AcademyPageLesson() {
  const { AppLayout } = MainLayout();

  return <>{AppLayout(AppBarAcademyLesson)}</>;
}

export default AcademyPageLesson;
