import { useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";

import PropertyPage from "./Pages/PropertyPage";
import TransactionPage from "./Pages/TransactionPage";
import SettingsPage from "./Pages/SettingsPage";
import HomePage from "./Pages/HomePage";
import LoginPage from "./Pages/LoginPage";
import SignUpPage from "./Pages/SignUpPage";
import DashboardPage from "./Pages/DashboardPage";
import ForclosurePage from "./Pages/ForclosurePage";
import KprSyariahPage from "./Pages/KprSyariahPage";
import PropertyDetailPage from "./Pages/PropertyDetailPage";

import AdminPage from "./Pages/AdminPages/AdminPage";
import AdminPropertyPage from "./Pages/AdminPages/AdminPropertyPage";
import AdminTransactionPage from "./Pages/AdminPages/AdminTransactionPage";
import AdminKprSyariahPage from "./Pages/AdminPages/AdminKprSyariahPage";
import AdminForeclosurePage from "./Pages/AdminPages/AdminForeclosurePage";
import AdminAddPropertyPage from "./Pages/AdminPages/AdminAddPropertyPage";
import TransactionDetailPage from "./Pages/TransactionDetailPage";
import AdminPropertyDetail from "./Components/AppComponents/AdminComponents/AdminPropertyDetail";
import AdminAppFormAddUser from "./Components/AppComponents/AdminComponents/AdminAppFormAddUser";
import AdminAppFormAddMember from "./Components/AppComponents/AdminComponents/AdminAppFormAddMember";
import AdminAppAdd from "./Components/AppComponents/AdminComponents/AdminAppAdd";
import AdminTransactionDetail from "./Pages/AdminPages/AdminTransactionDetail";
import AcademyPage from "./Pages/AcademyPage";
import useAuthentication from "./Configs/useAuthentication";
import AcademyPageDetails from "./Pages/AcademyPageDetails";
import ProtectRoutes from "./Routes/ProtectRoutes";
import AttentionPage from "./Pages/AttentionPage";
import ProtectRoutesUser from "./Routes/ProtectRoutesUser";
import AdminEditProperty from "./Components/AppComponents/AdminComponents/AdminEditProperty";
import AcademyPageLesson from "./Pages/AcademyPageLesson";
import TransactionPaymentPage from "./Pages/TransactionPaymentPage";
import AdminTransactionAddPage from "./Pages/AdminPages/AdminTransactionAddPage";

function App() {
  var store = require("store");
  const [auth, setAuth] = useState("");

  // const userData = store.get("userData")
  // const userRole = userData.type_user
  // console.log(userRole, 'role data nih')

  useEffect(() => {
    setAuth(sessionStorage.getItem("Auth Token"));
    console.log("session=", sessionStorage.getItem("Auth Token"));
  }, [sessionStorage.getItem("Auth Token")]);

  const isLogedIn = useAuthentication();
  console.log(isLogedIn, "isLogedIn");

  // useEffect(() => {
  //   setAuth(sessionStorage.getItem("Auth Token"));

  return (
    <>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        {/* <Route path="signup" element={<SignUpPage />} /> */}
        {/* <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        /> */}
        {isLogedIn && (
          <>
            <Route
              path="dashboard"
              element={
                <ProtectRoutesUser>
                  <DashboardPage />
                </ProtectRoutesUser>
              }
            />
            <Route
              path="property"
              element={
                <ProtectRoutesUser>
                  <PropertyPage />
                </ProtectRoutesUser>
              }
            />
            <Route
              path="property/:id"
              element={
                <ProtectRoutesUser>
                  <PropertyDetailPage />
                </ProtectRoutesUser>
              }
            />
            <Route
              path="transaction"
              element={
                <ProtectRoutesUser>
                  <TransactionPage />
                </ProtectRoutesUser>
              }
            />
            <Route
              path="transaction/:id"
              element={
                <ProtectRoutesUser>
                  <TransactionDetailPage />
                </ProtectRoutesUser>
              }
            />
            <Route
              path="transaction/:id/payment"
              element={
                <ProtectRoutesUser>
                  <TransactionPaymentPage />
                </ProtectRoutesUser>
              }
            />
            <Route path="academy" element={<AcademyPage />} />
            <Route path="academy/:id" element={<AcademyPageDetails />} />
            <Route
              path="academy/:courseId/:lessonId"
              element={<AcademyPageLesson />}
            />
            <Route
              path="foreclosure"
              element={
                <ProtectRoutesUser>
                  <ForclosurePage />s
                </ProtectRoutesUser>
              }
            />
            <Route
              path="kprsyariah"
              element={
                <ProtectRoutesUser>
                  <KprSyariahPage />
                </ProtectRoutesUser>
              }
            />
            <Route
              path="admin"
              element={
                <ProtectRoutes>
                  <AdminPage />
                </ProtectRoutes>
              }
            >
              <Route path="/admin/property" element={<AdminPropertyPage />} />
              <Route path="/admin/add" element={<AdminAppAdd />} />
              <Route path="/admin/add/user" element={<AdminAppFormAddUser />} />
              <Route
                path="/admin/add/member"
                element={<AdminAppFormAddMember />}
              />
              <Route
                path="/admin/property/new"
                element={<AdminAddPropertyPage />}
              />
              <Route
                path="/admin/property/:id"
                element={<AdminPropertyDetail />}
              />
              <Route
                path="/admin/property/edit/:id"
                element={<AdminEditProperty />}
              />
              <Route
                path="/admin/transaction"
                element={<AdminTransactionPage />}
              />
              <Route
                path="/admin/transaction/add"
                element={<AdminTransactionAddPage />}
              />
              <Route
                path="/admin/transaction/:id"
                element={<AdminTransactionDetail />}
              />
              <Route
                path="/admin/foreclosure"
                element={<AdminForeclosurePage />}
              />
              <Route
                path="/admin/kprsyariah"
                element={<AdminKprSyariahPage />}
              />
            </Route>
            <Route path="settings" element={<SettingsPage />} />
            <Route path="attention" element={<AttentionPage />} />
          </>
        )}
      </Routes>
    </>
  );
}

export default App;
