import { Box } from "@chakra-ui/react";
import React from "react";
import AdminAppTableTransaction from "../../Components/AppComponents/AdminComponents/AdminAppTableTransaction";

function AdminTransactionPage() {
  return (
    <Box w="full" p="5" > 
      <AdminAppTableTransaction />
    </Box>
  );
}

export default AdminTransactionPage;
