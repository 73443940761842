import {
  Container,
  Stack,
  Heading,
  FormControl,
  Text,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Link,
  HStack,
  useBreakpointValue,
  SimpleGrid,
  Select,
  InputLeftElement,
  InputGroup,
} from "@chakra-ui/react";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";

import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import colors from "../../../Configs/colors";
import { auth, db } from "../../../Configs/firebase";

const AdminAppFormAddMember = () => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [user, setUser] = useState("");
  const [price, setPrice] = useState("");
  const [duration, setDuration] = useState("");

  let navigate = useNavigate();

  const onHandleRegister = async () => {
    console.log(
      name,
      password,
      confirmationPassword,
      email,
      address,
      phoneNumber,
      user,
      price,
      duration
    );
    if (password != confirmationPassword) {
      console.log("Password and confirmation is not match");
      return "";
    }

    createUserWithEmailAndPassword(email, password)
      .then(async (response) => {
        const docRef = await setDoc(doc(db, `users/${response.user.uid}`), {
          user_email: email,
          user_name: name,
          user_address: address,
          user_phone: phoneNumber,
          user_role: user,
          membership_duration: duration,
          membership_price: price,
          uid: response.user.uid,
          active: false,
          uid: response.user.uid,
        });
        console.log("Document written with ID: ", docRef);
      })
      .then(() => navigate("/admin/add"))
      .catch((error) => alert(error.message));
  };

  return (
    <Container maxW="4xl" py={{ base: "6", md: "12" }}>
      <Stack spacing="8">
        <Stack spacing="6">
          <Stack spacing="3">
            <Heading
              size={useBreakpointValue({ base: "xs", md: "sm" })}
              fontSize={20}
            >
              Register Account
            </Heading>
          </Stack>
        </Stack>
        <SimpleGrid columns={[1, null, 2]} spacingX={7}>
          <Stack spacing={5}>
            <FormControl isRequired>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                id="name"
                type="name"
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Input
                id="password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormHelperText color={colors.darklight} fontSize="50%">
                At least 8 characters long
              </FormHelperText>
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="address">Address</FormLabel>
              <Input
                id="address"
                type="address"
                onChange={(e) => setAddress(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="price">Price </FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.500"
                  fontSize="1.2em"
                  children="IDR"
                />
                <Input
                  id="price"
                  type="number"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </InputGroup>
            </FormControl>
          </Stack>
          {/* blabla */}
          <Stack spacing={5}>
            <FormControl isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="confirmpassword">Confirm Password</FormLabel>
              <Input
                id="confirmpassword"
                type="password"
                onChange={(e) => setConfirmationPassword(e.target.value)}
              />
              <FormHelperText color={colors.darklight} fontSize="50%">
                At least 8 characters long
              </FormHelperText>
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
              <Input
                id="phoneNumber"
                type="number"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="user">User</FormLabel>
              <Select
                placeholder="Select option"
                onChange={(e) => setUser(e.target.value)}
              >
                <option value="admin">Admin</option>
                <option value="user">User</option>
                <option value="member">Member</option>
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="user">Duration</FormLabel>
              <Select
                placeholder="Select option"
                onChange={(e) => setDuration(e.target.value)}
              >
                <option value="admin">6 Mounth</option>
                <option value="user">1 Year</option>
                <option value="member">3 year</option>
              </Select>
            </FormControl>
          </Stack>
        </SimpleGrid>
        <SimpleGrid spacing="4" columns={[1, null, 2]} w="50%">
          <Button
            onClick={() => onHandleRegister()}
            backgroundColor={colors.theme}
            color="#ffff"
          >
            Create Account
          </Button>
          <Button colorScheme="red" border="1px">
            Cancel
          </Button>
        </SimpleGrid>
        <HStack justify="end" spacing="1">
          <Text fontSize="sm" color="muted">
            Deactive Your Account
          </Text>
          <Link to="/login">
            <Button variant="link" colorScheme="red" size="sm">
              Deactive
            </Button>
          </Link>
        </HStack>
      </Stack>
    </Container>
  );
};

export default AdminAppFormAddMember;
