import axios from "axios";

const CreateInvoicePaymentLink = async (
  external_id,
  amount,
  invoice_id,
  email
) => {
  const result = await axios.post(
    `https://payment.importir.com/payment/xendit/create-invoice`,
    {
      company: "ESA",
      external_id: external_id,
      payment_method: "INVOICE_TRANSFER_VA",
      amount: amount,
      invoice_id: invoice_id,
      payer_email: email,
      success_redirect_url: "https://importir.id/affliate/AFF0425123771ODA",
      failure_redirect_url: "https://importir.id/affliate/AFF0425123771ODA",
    }
  );
  const data = result.data;
  console.log(result.data);
  return data;
};

// const CallbackXendit = async () => {
//   const callback = await axios.post(
//     `https://stage-payment.importir.com/payment/xendit/notification`,
//     {
//       id: "618dd4da59269da348382890",
//       external_id: "BP51168-202111120943",
//       user_id: "5f28feddddf3286433c547c8",
//       is_high: false,
//       credit_card_token: "618dd4eaec3d4c001cdc96d6",
//       credit_card_charge_id: "618dd4eeec3d4c001cdc96d8",
//       payment_method: "CREDIT_CARD",
//       status: "PAID",
//       merchant_name: "shop",
//       amount: 11664431,
//       paid_amount: 11664431,
//       paid_at: "2021-11-12T02:43:58.527Z",
//       payer_email: "rizal@digitalstrategy.id",
//       description:
//         "INVOICE BP51168 | Bill : 11.290.136,00 | Services Fee, 2.9% from Transfer Amount + 2000 : 340.268,51 | ppn, 10% from Service Fee : 34.026,85 | Total Services Fee : 374.295,36",
//       adjusted_received_amount: 11664431,
//       success_redirect_url: "https://stage.importir.com/home/bill/detail/51168",
//       failure_redirect_url: "https://stage.importir.com/home/bill/detail/51168",
//       created: "2021-11-12T02:43:39.788Z",
//       updated: "2021-11-12T02:43:58.916Z",
//       currency: "IDR",
//       payment_channel: "CREDIT_CARD",
//     }
//   );
// };

export { CreateInvoicePaymentLink };
